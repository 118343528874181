import styled from 'styled-components'
import { Container } from 'react-grid-system'

import homeBackground from '../../assets/HomeBackground.png'
import { buttonFormList } from '../../styles/PSPHelperStyles.styles'

const ContainerStyled = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  max-width: 1270px !important;

  background-image: url(${homeBackground});
  ${buttonFormList}
`

export { ContainerStyled }
