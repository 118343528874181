import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ButtonSelector, ModalHint } from 'pyrexx-react-library'
import Navigator from '../../Navigator/Navigator'

const AppointmentSelection = (props) => {
  const { prevStep, nextStep, saveFormInputs } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)

  const schema = Yup.object({
    serviceCategory: Yup.string().required(t('CHOOSE ONE CATEGORY')),
  })

  const formik = useFormik({
    initialValues: {
      serviceCategory: undefined,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (values.isOptionAvailable) {
        saveFormInputs({
          serviceCategory: values.serviceCategory,
          appointmentType: 'TI',
        })
        navigate(nextStep)
      } else {
        setShowModal(true)
      }
    },
  })

  const services = [
    {
      serviceCategory: 'SMOKEDETECTOR',
      label: t('SMOKE DETECTOR'),
      isOptionAvailable: true,
    },
    {
      serviceCategory: 'DRINKWATER',
      label: t('DRINKING WATER ANALYSIS'),
      isOptionAvailable: false,
    },
    {
      serviceCategory: 'MEASUREMENT',
      label: t('MEASUREMENT SERVICE'),
      isOptionAvailable: true,
    },
  ]

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalHint
        closeOnOutsideClick={false}
        isOpen={showModal}
        message={t(
          'IF WE COULD NOT TAKE ENOUGH SAMPLES, WE WILL INFORM YOU ABOUT A NEW APPOINTMENT',
        )}
        buttonCloseMessage={
          <>
            {t('YES I UNDERSTAND')} <br />
            {t('BACK TO THE HOME PAGE!')}
          </>
        }
        title={t('NOTICE')}
        handleModalClose={() => {
          setShowModal(false)
          navigate('/')
        }}
      />

      <Row justify='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          {services.map((service) => (
            <ButtonSelector
              key={'appointment-selection-' + service.serviceCategory}
              type='button'
              isActive={
                formik.values.serviceCategory === service.serviceCategory
              }
              onClick={() => {
                formik.setFieldValue('serviceCategory', service.serviceCategory)
                formik.setFieldValue(
                  'isOptionAvailable',
                  service.isOptionAvailable,
                )
              }}
            >
              {service.label}
            </ButtonSelector>
          ))}
        </Col>
      </Row>

      <Navigator
        prevStep={prevStep}
        isNextButtonDisabled={!formik.values.serviceCategory}
      />
    </form>
  )
}

AppointmentSelection.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default AppointmentSelection
