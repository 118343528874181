import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'
import ContactInput from '../../../../components/Steps/Inputs/ContactInput'
import { useNavigate } from 'react-router-dom'
import { AvailableRoutes } from '../../../../configs/RouteConfig'

const Step3 = (props) => {
  const { prevStep, nextStep, saveFormInputs, savedValues } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!savedValues?.serviceCategory) {
      navigate('../../' + AvailableRoutes.appointmentProblem.root)
    }
  }, [savedValues.serviceCategory])

  return (
    <StepContainer
      title={t('APPOINTMENT PLANNING')}
      description={t('PROBLEM WITH A SMOKE DETECTOR')}
      subtitle={t('CONTACT INFO')}
      form={
        <ContactInput
          savedValues={savedValues}
          saveFormInputs={saveFormInputs}
          prevStep={prevStep}
          nextStep={nextStep}
          maxSteps={(AvailableRoutes.appointmentProblem.steps.length - 2)}
          currentStep={3}
        />
      }
    />
  )
}

Step3.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default Step3
