import styled from 'styled-components'
import { Container } from 'react-grid-system'

import { helperStyles, portalTheme } from 'pyrexx-react-library'
import { buttonFormList } from '../../../../styles/PSPHelperStyles.styles'

const ContainerStyled = styled(Container)`
  background-color: ${portalTheme.color.basicColorWhite};
  ${buttonFormList};
`

const TitleStyled = styled.h1`
  text-align: center;
  font-family: ${portalTheme.font.family.quicksand};
  margin-block-start:1em;
  margin-block-end: 0.5em;
  padding: 0;
  @media screen and (max-width: 768px) {
    font-size: ${portalTheme.font.size.headingTiny};
  }
`

const DescriptionStyled = styled.p`
  text-align: center;
  font-size: ${portalTheme.font.size.bodyRegular};
  margin-block-start:1em;
  font-family: ${portalTheme.font.family.quicksand};
  margin-block-end: 0.5em;
`

const SubtitleStyled = styled.h3`
  text-align: center;
  font-size: ${portalTheme.font.size.headingTiny};
  margin-block-start:1em;
  margin-block-end: 1.5em;
  font-family: ${portalTheme.font.family.quicksand};
`

export { ContainerStyled, TitleStyled, DescriptionStyled, SubtitleStyled }
