import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import StepContainer from '../../components/Steps/Containers/StepContainer/StepContainer'
import ContactContainer from './ContactContainer'

import { AvailableRoutes } from '../../configs/RouteConfig'
import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'

const Contact = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_CONTACT')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_CONTACT')}
        />
      </Helmet>

      <StepContainer
        prevStep={'../' + AvailableRoutes.help}
        showBackArrow
        title={t('CONTACT')}
        description={t('DO YOU HAVE QUESTIONS?')}
        form={<ContactContainer />}
      />
    </>
  )
}

export default Contact
