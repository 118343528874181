import i18 from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// NOT WORKING FOR SOME REASON...
const detectionOptions = {
  order: ['cookie', 'navigator', 'localStorage'],
}

i18
  .use(Backend)
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(LanguageDetector) // detect user language
  .init({
    backend: {
      loadPath: '/locales/{lng}/LC_MESSAGES/translation.json',
    },
    // detection: detectionOptions,
    supportedLngs: ['de_DE', 'en_GB', 'de', 'en'],
    nsSeparator: false,
    keySeparator: false,
    returnEmptyString: false,
    interpolation: {
      prefix: '{',
      suffix: '}',
      escapeValue: false,
    },
    fallbackLng: ['de_DE'],
    debug: false,
  })

export default i18
