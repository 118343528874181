import styled from 'styled-components'
import { Container } from 'react-grid-system'

import { portalTheme } from 'pyrexx-react-library'

import { buttonFormList } from '../../styles/PSPHelperStyles.styles'

const ContainerStyled = styled(Container)`
  background-color: ${portalTheme.color.basicColorWhite};
  ${buttonFormList}
`

const TitleStyled = styled.h1`
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`

const DescriptionStyled = styled.p`
  text-align: center;
`

export { ContainerStyled, TitleStyled, DescriptionStyled }
