import React from 'react'
import { Row, Col, Container } from 'react-grid-system'

import { ButtonSelector } from 'pyrexx-react-library'

const SlotContent = ({ slot }) => {
  return (
    <Container style={{ margin: 0, padding: 0, width: '100%' }}>
      <Row align='stretch' justify='between' nogutter>
        <Col>
          <span>{slot.date}</span>
        </Col>
        <Col>
          <span>{slot.day}</span>
        </Col>
        <Col>
          <span>
            {slot.from} - {slot.to}
          </span>
        </Col>
      </Row>
    </Container>
  )
}

const Slot = (props) => {
  const {
    id,
    slot,
    handleSlotSelection,
    currentSlot,
    validatedEncryptedApartmentId,
  } = props

  return (
    <ButtonSelector
      type='button'
      isActive={currentSlot === id}
      onClick={() =>
        handleSlotSelection(
          id,
          slot.from,
          slot.to,
          validatedEncryptedApartmentId,
        )
      }
    >
      <SlotContent slot={slot} />
    </ButtonSelector>
  )
}

export default Slot
