import React from 'react'
import PropTypes from 'prop-types'

import MobileMenuList from './MobileMenuList'

const MobileMenuContent = ({ lists, currentLanguage, handleMobileMenu }) => {
  return (
    <nav>
      {lists
        ? lists.map((list) => (
            <MobileMenuList
              key={list.title.label}
              list={list}
              currentLanguage={currentLanguage}
              handleMobileMenu={handleMobileMenu}
            />
          ))
        : null}
    </nav>
  )
}

export default MobileMenuContent

MobileMenuContent.propTypes = {
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }).isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  handleMobileMenu: PropTypes.func.isRequired,
}
