import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import {
  ApolloClient,
  gql,
  InMemoryCache,
  useQuery as useApolloQuery,
} from '@apollo/client'

import { Spinner } from 'pyrexx-react-library'
import StepContainer from '../../components/Steps/Containers/StepContainer/StepContainer'
import { LivechatForm } from './LivechatForm'

import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { AvailableRoutes } from '../../configs/RouteConfig'
import { useAppContext } from '../../contexts/AppContext'

const GET_LIVECHAT_TOPICS = gql`
  query LivechatTopics($lang: String) {
    getFaqItems(portal: "psp_chat", lang: $lang) {
      id
      categories {
        id
        name
        topics: questions {
          id
          label: question
        }
      }
    }
  }
`

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
})

const Livechat = () => {
  const { t } = useTranslation()
  const { currentLanguage, chat } = useAppContext()

  const { data, loading } = useApolloQuery(GET_LIVECHAT_TOPICS, {
    variables: {
      lang: currentLanguage,
    },
    client,
  })

  const isZammadOnline = chat?.state === 'online'
  const availableTopics = data?.getFaqItems?.categories[0]?.topics || []
  const showForm = isZammadOnline && availableTopics.length > 0

  if (loading) return <Spinner />

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_LIVECHAT')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_LIVECHAT')}
        />
      </Helmet>

      <StepContainer
        prevStep={'../' + AvailableRoutes.contact}
        showBackArrow
        title={t('LIVECHAT')}
        form={
          showForm ? (
            <LivechatForm availableTopics={availableTopics} />
          ) : (
            <p>{t('WE ARE OFFLINE. TRY AGAIN LATER.')}</p>
          )
        }
      />
    </>
  )
}

export default Livechat
