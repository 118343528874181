import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'
import SlotSelectionContainer from '../../../../components/Slot/SlotSelectionContainer'

import { AvailableRoutes } from '../../../../configs/RouteConfig'

const Step2 = (props) => {
  const { prevStep, nextStep, saveFormInputs, savedValues } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!savedValues?.serviceCategory) {
      navigate('../../' + AvailableRoutes.appointmentProblem.root)
    }
  }, [savedValues?.serviceCategory])

  return (
    <StepContainer
      title={t('APPOINTMENT PLANNING')}
      description={t('PROBLEM WITH A SMOKE DETECTOR')}
      subtitle={t('SELECT AVAILABLE SLOT')}
      form={
        <SlotSelectionContainer
          savedValues={savedValues}
          saveFormInputs={saveFormInputs}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      }
    />
  )
}

Step2.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default Step2
