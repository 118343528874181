import React, { useState, useEffect } from 'react'

import Star from './Star'

const rateList = [1, 2, 3, 4, 5]

const initialState = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
}

const generateActiveStars = (list, value) => {
  return list
    .filter((v) => v <= value)
    .reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: true,
      }
    }, initialState)
}

const Stars = (props) => {
  const { questionId, rateValue, handleStarSelection } = props

  const [isActive, setIsActive] = useState(initialState)
  const [isHover, setIsHover] = useState(initialState)

  useEffect(() => {
    const activeStars = generateActiveStars(rateList, rateValue)
    setIsActive(activeStars)
  }, [rateValue])

  const handleStarClick = (rateValue) => {
    const activeStars = generateActiveStars(rateList, rateValue)
    setIsActive(activeStars)
    handleStarSelection({ id: questionId, value: rateValue })
  }

  const handleStarHover = (rateValue) => {
    const activeStars = generateActiveStars(rateList, rateValue)
    setIsHover(activeStars)
  }

  const clearStars = () => setIsHover(initialState)

  return (
    <div>
      {rateList.map((rateValue) => {
        return (
          <Star
            key={rateValue}
            isActive={isActive[rateValue]}
            isHover={isHover[rateValue]}
            onClick={() => handleStarClick(rateValue)}
            onMouseEnter={() => handleStarHover(rateValue)}
            onMouseLeave={clearStars}
          />
        )
      })}
    </div>
  )
}

export default Stars
