import styled from 'styled-components'

import { portalTheme } from 'pyrexx-react-library'

const Row = styled.div`
  border: 1px solid;
  border-color: ${portalTheme.color.linkColorSecondary};

  height: 5rem;
  margin-bottom: 1rem;
  padding: 1rem;

  display: flex;
`

const Col = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const LeftCol = styled(Col)`
  justify-content: flex-start;
`

const RightCol = styled(Col)`
  justify-content: flex-end;
`

const Title = styled.h4`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: ${portalTheme.font.size.bodyRegular};
`

const DownloadLink = styled.a`
  background-color: white;
  border: none;

  cursor: pointer;
`

const DownloadImg = styled.img`
  width: 30px;
  height: auto;
`

export { Row, LeftCol, RightCol, Title, DownloadLink, DownloadImg }
