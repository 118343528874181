import React, { useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import generatePrevNextRoutes from './generatePrevNextRoutes'

export function GenerateSteps(props) {
  const { steps = [], appointmentTypeDescriptions, currentLanguage } = props

  const [savedValues, setSavesValues] = useState({})

  const saveFormInputs = (values) => {
    setSavesValues((prevState) => ({ ...prevState, ...values }))
  }

  // Saved values from the forms.
  // console.log('SAVED VALUES: ', savedValues)

  return (
    <Routes>
      {steps.map((step) => {
        const CurrentStep = step.component

        const [prevStep, nextStep] = generatePrevNextRoutes({
          steps,
          routeIndexStep: step.index,
        })

        // See console.log to check how the routes are generated.
        // console.log(prevStep, ' | ', step.slug, ' | ', nextStep)

        return (
          <Route
            index={step.index === 0}
            key={step.slug}
            path={step.slug}
            /* CurrentStep consists of components from every route. e.g. Start, Step1, Step2, etc. */
            element={
              <CurrentStep
                prevStep={prevStep}
                nextStep={nextStep}
                savedValues={savedValues}
                saveFormInputs={saveFormInputs}
                appointmentTypeDescriptions={appointmentTypeDescriptions}
                currentLanguage={currentLanguage}
              />
            }
          />
        )
      })}

      <Route path='*' element={<Navigate to={steps[0]?.slug} />} />
    </Routes>
  )
}
