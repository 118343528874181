import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DeleteButtonStyled } from './DeleteButton.styles'

import {Icon,portalTheme} from "pyrexx-react-library";


const DeleteButton = (props) => {
  const { t } = useTranslation()

  return (
    <DeleteButtonStyled {...props}>
      <Icon
        icon='trash'
        size='25px'
        color={portalTheme.color.errorColor}
      /> {t('DELETE TRACKING TICKET')}
    </DeleteButtonStyled>
  )
}

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default DeleteButton
