import React, { useEffect, useState } from 'react'
import { ButtonPrimary } from 'pyrexx-react-library'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../contexts/AppContext'

const startChat = () => {
  document?.getElementById('zammad-button').click()
}

function LivechatZammad(props) {
  const { msg } = props

  const { t } = useTranslation()
  const { chat } = useAppContext()

  const [error, setError] = useState(false)

  useEffect(() => {
    try {
      // src: https://community.zammad.org/t/get-information-in-chat/7513
      chat.onConnectionEstablished = (data) => {
        if (data.state === 'ok') {
          // eslint-disable-next-line
          ZammadChat.prototype.onConnectionEstablished.call(chat, data)

          chat.send('chat_session_message', {
            id: Date.now(),
            chat_id: chat.id,
            session_id: chat.sessionId,
            content: msg,
          })
        }
      }
    } catch (e) {
      setError(true)
    }
  }, [msg])

  if (error) {
    return <p>{t('CHAT NOT AVAILABLE AT THE MOMENT')}</p>
  }

  return (
    <ButtonPrimary
      type='submit'
      // disabled={disableSendButton}
      className='open-zammad-chat'
      onClick={startChat}
    >
      {t('START CHAT')}
    </ButtonPrimary>
  )
}

export default LivechatZammad
