import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import isValid from '../../helper/isValid'
import { QuestionPulldown } from './FaqList.styles'

const FaqContent = (props) => {
  const { questions = [] } = props

  const { t } = useTranslation()

  return isValid(questions) ? (
    questions.map((question) => {
      return (
        <ul key={question.id}>
          <QuestionPulldown
            headerLabelElement={
              <StyledListItem>{question.question}</StyledListItem>
            }
            values={<p dangerouslySetInnerHTML={{ __html: question.answer }} />}
          />
        </ul>
      )
    })
  ) : (
    <div>{t('NO AVAILABLE QUESTIONS')}</div>
  )
}

const StyledListItem = styled.li`
  list-style: none;
  font-weight: bold;
`

export default FaqContent
