import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'
import AppointmentResultContainer from '../../../../components/AppointmentResult/AppointmentResultContainer'

import { AvailableRoutes } from '../../../../configs/RouteConfig'

const Step3 = (props) => {
  const { savedValues, prevStep } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!savedValues?.lastName || !savedValues?.firstName) {
      navigate('../../' + AvailableRoutes.appointmentCheck.root)
    }
  }, [savedValues.lastName, savedValues.firstName])

  return (
    <StepContainer
      title={t('APPOINTMENT CHECK')}
      description={t('APPOINTMENT CHECK')}
      form={
        <AppointmentResultContainer
          savedValues={savedValues}
          prevStep={prevStep}
        />
      }
    />
  )
}

export default Step3
