import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import StepContainer from '../../components/Steps/Containers/StepContainer/StepContainer'
import NewsletterForm from './NewsletterForm'

import { AvailableRoutes } from '../../configs/RouteConfig'
import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'

const Newsletter = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_NEWSLETTER_SUBSCRIPTION')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_NEWSLETTER_SUBSCRIPTION')}
        />
      </Helmet>

      <StepContainer
        prevStep={'/'}
        showBackArrow
        title={t('NEWSLETTER REGISTRATION')}
        description={t('REGISTER HERE IF YOU ALWAYS WANT TO STAY UP TO DATE')}
        form={<NewsletterForm currentLanguage={currentLanguage} />}
      />
    </>
  )
}

export default Newsletter
