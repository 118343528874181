import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { portalTheme } from 'pyrexx-react-library'

const linkCSS = css``

const titleCSS = css`
  font-weight: 500;
`
const activeCSS = css`
  border-left-color: ${portalTheme.color.basicColorAccentRed};
  font-weight: ${portalTheme.font.weight.bold};
`

const MobileMenuRouteLinkStyled = styled(NavLink)`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.light};
  font-size: ${portalTheme.font.size.navigationMenu};

  color: ${portalTheme.color.basicColorBlack};
  text-decoration: none;

  padding: 3px 0;
  padding-left: 0.5em;

  border-left: 3px solid;
  border-left-color: white;

  &:hover,
  &:active,
  &.active {
    ${activeCSS}
  }

  ${({ type }) => {
    if (type === 'link') return linkCSS
    else if (type === 'title') return titleCSS
    else return ''
  }}
`

const MobileMenuRouteLink = (props) => {
  const { linkTo, handleMobileMenu, type = 'link', children } = props

  return (
    <MobileMenuRouteLinkStyled
      to={linkTo}
      onClick={() => handleMobileMenu(false)}
      type={type}
    >
      {children}
    </MobileMenuRouteLinkStyled>
  )
}

MobileMenuRouteLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  handleMobileMenu: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['link', 'title']),
}

export default MobileMenuRouteLink
