import React from 'react'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ButtonPrimary, ButtonSelector } from 'pyrexx-react-library'

import { AvailableRoutes } from '../../configs/RouteConfig'
import { useAppContext } from '../../contexts/AppContext'

const AppointmentInput = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentLangPath } = useAppContext()

  const schema = Yup.object({
    nextRoute: Yup.string().required(t('CHOOSE ONE CATEGORY')),
  })

  const formik = useFormik({
    initialValues: {
      nextRoute: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      navigate('../' + values.nextRoute)
    },
  })

  const appointmentRoutes = [
    {
      route: currentLangPath + AvailableRoutes.appointmentMissed.root,
      label: t('APPOINTMENT MISSED'),
    },
    {
      route: currentLangPath + AvailableRoutes.appointmentPlanning.root,
      label: t('APPOINTMENT PLANNING'),
    },
    {
      route: currentLangPath + AvailableRoutes.appointmentCheck.root,
      label: t('APPOINTMENT CHECK'),
    },
    {
      route: currentLangPath + AvailableRoutes.appointmentTracking.root,
      label: t('APPOINTMENT TRACKING'),
    },
  ]

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row justify='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          {appointmentRoutes.map((route) => (
            <ButtonSelector
              key={route.route}
              type='button'
              isActive={formik.values.nextRoute === route.route}
              onClick={() => {
                formik.setFieldValue('nextRoute', route.route)
              }}
            >
              {route.label}
            </ButtonSelector>
          ))}
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs='content'>
          <ButtonPrimary type='submit' disabled={!formik.values.nextRoute}>
            {t('NEXT')}
          </ButtonPrimary>
        </Col>
      </Row>
    </form>
  )
}

export default AppointmentInput
