import React from 'react'
import PropTypes from 'prop-types'

import MobileMenuListTitle from './MobileMenuListTitle'
import MobileMenuListLinks from './MobileMenuListLinks'

const MobileMenuList = ({ list = {}, currentLanguage, handleMobileMenu }) => {
  return (
    <>
      <MobileMenuListTitle
        title={list.title}
        currentLanguage={currentLanguage}
        handleMobileMenu={handleMobileMenu}
      />
      <MobileMenuListLinks
        links={list.links}
        currentLanguage={currentLanguage}
        handleMobileMenu={handleMobileMenu}
      />
    </>
  )
}

export default MobileMenuList

MobileMenuList.propTypes = {
  list: PropTypes.shape({
    title: PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  handleMobileMenu: PropTypes.func.isRequired,
}
