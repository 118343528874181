import React, { useState } from 'react'
import { Visible } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import ContactFormMobileInputs from './ContactFormMobileInputs'
import ContactFormDesktopInputs from './ContactFormDesktopInputs'

import { ADD_CONTACT } from '../../mutations/AddContactTicketMutation'

const ContactForm = () => {
  const { t } = useTranslation()

  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    status: '',
    message: '',
  })

  const [addContact] = useMutation(ADD_CONTACT)

  const schema = Yup.object({
    appointmentId: Yup.number()
      .nullable(true)
      .transform((v, o) => (o === '' ? null : v))
      .typeError(t('PLEASE ENTER ONLY NUMBERS'))
      .integer(t('PLEASE PROVIDE NUMBER WITHOUT COMMA')),
    contactName: Yup.string().required(t('WE NEED A CONTACT NAME')),
    address: Yup.string(),
    apartmentNr: Yup.string(),
    floor: Yup.string(),
    zip: Yup.string(),
    town: Yup.string(),
    contactEmail: Yup.string().email().required(t('WE NEED A CONTACT EMAIL')),
    question: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      appointmentId: '',
      contactName: '',
      address: '',
      apartmentNr: '',
      floor: '',
      zip: '',
      town: '',
      contactEmail: '',
      question: '',
    },
    validationSchema: schema,
    onSubmit: (values, formik) => {
      setSubmitStatus({
        status: '',
        message: '',
        loading: true,
      })

      const variables = { contactInfo: schema.cast(values) }

      addContact({
        variables,
        onCompleted: (data) => {
          if (data?.addContactTicket?.success) {
            setSubmitStatus({
              loading: false,
              status: 'success',
              message: t('CONTACT REQUEST WAS SUCCESSFULLY SENT'),
            })
            formik.resetForm()
          } else {
            setSubmitStatus({
              loading: false,
              status: 'error',
              message: t(
                'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
              ),
            })
          }
        },
        onError: () => {
          setSubmitStatus({
            loading: false,
            status: 'error',
            message: t(
              'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
            ),
          })
        },
      })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Visible xs sm md>
        <ContactFormMobileInputs formik={formik} submitStatus={submitStatus} />
      </Visible>

      <Visible lg xl>
        <ContactFormDesktopInputs formik={formik} submitStatus={submitStatus} />
      </Visible>
    </form>
  )
}

export default ContactForm
