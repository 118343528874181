import React from 'react'
import { useTranslation } from 'react-i18next'

import DownloadBox from './DownloadBox'

import isValid from '../../helper/isValid'

const DownloadList = (props) => {
  const { files = [] } = props

  const { t } = useTranslation()

  return isValid(files) ? (
    files
      .filter((item) => item !== null)
      .map((file) => <DownloadBox key={file.id} file={file} />)
  ) : (
    <p>{t('NO AVAILABLE FILES')}</p>
  )
}

export default DownloadList
