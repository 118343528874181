import styled from 'styled-components'
import { Container } from 'react-grid-system'

import { portalTheme } from 'pyrexx-react-library'

const ContainerStyled = styled(Container)`
  background-color: ${portalTheme.color.basicColorWhite};
`

const TitleStyled = styled.h1`
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`

const DescriptionStyled = styled.p`
  text-align: center;
`

const SubtitleStyled = styled.h3`
  text-align: center;

  font-size: 14px;
`

export { ContainerStyled, TitleStyled, DescriptionStyled, SubtitleStyled }
