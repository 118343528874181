import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'

import BackButton from '../../components/BackButton/BackButton'

import { ContainerStyled, DescriptionStyled, TitleStyled } from './Help.styles'
import { AvailableRoutes } from '../../configs/RouteConfig'

const HelpContainer = (props) => {
  const { title, description = '', form } = props

  return (
    <ContainerStyled component='article' xs sm md>
      <Row justify='between' align='center' wrap='nowrap' nogutter>
        <Col component='nav' xs={1}>
          <BackButton prevStep={'/'} />
        </Col>

        <Col>
          <TitleStyled>{title}</TitleStyled>
        </Col>

        <Col xs={1} />
      </Row>

      <Row justify='center' align='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          <DescriptionStyled>{description}</DescriptionStyled>
        </Col>
      </Row>

      <Row justify='center' style={{ marginBottom: '1rem' }}>
        <Col xs={12} sm={6} md={4} lg={3}>
          {form}
        </Col>
      </Row>
    </ContainerStyled>
  )
}

HelpContainer.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.any,
  form: PropTypes.element.isRequired,
}

export default HelpContainer
