import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ButtonPrimary, portalTheme } from 'pyrexx-react-library'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 2rem;
`

const Description = styled.p`
  font-size: 24px;
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.light};

  text-align: center;
  margin: 3rem 0;
  margin-top: 0;
`

const ErrorMessage = styled.pre`
  color: red;
`

const ErrorFallback = (props) => {
  const { error, resetErrorBoundary } = props
  const { t } = useTranslation()

  return (
    <Container>
      <Description>{t('SOMETHING WENT WRONG')}</Description>
      <ErrorMessage>{error?.message}</ErrorMessage>
      <ButtonPrimary onClick={resetErrorBoundary}>
        {t('TRY AGAIN')}
      </ButtonPrimary>
    </Container>
  )
}

export default ErrorFallback
