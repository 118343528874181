import React from 'react'
import { useTranslation } from 'react-i18next'

import FaqContent from './FaqContent'

import { SimplePullDownStyled } from './FaqList.styles'
import isValid from '../../helper/isValid'

const FaqList = (props) => {
  const { categories = [] } = props

  const { t } = useTranslation()

  return isValid(categories) ? (
    categories.map((category) => {
      return (
        <SimplePullDownStyled
          key={category.id}
          headerLabelElement={category.name}
          values={<FaqContent questions={category.questions} />}
        />
      )
    })
  ) : (
    <div>{t('NO AVAILABLE CATEGORIES')}</div>
  )
}

export default FaqList
