import React from 'react'
import {Col, Row} from 'react-grid-system'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ButtonPrimary, ButtonSelector, SimpleLink} from 'pyrexx-react-library'

import {AvailableRoutes} from '../../configs/RouteConfig'
import {useAppContext} from '../../contexts/AppContext'

const HomeInput = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {currentLangPath} = useAppContext()

  const schema = Yup.object({
    nextRoute: Yup.string().required(t('CHOOSE ONE CATEGORY')),
  })

  const formik = useFormik({
    initialValues: {
      nextRoute: undefined,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      navigate(values.nextRoute)
    },
  })

  const pspRoutes = [
    {
      route: currentLangPath + AvailableRoutes.appointment,
      label: t('APPOINTMENT'),
    },
    {
      route: currentLangPath + AvailableRoutes.appointmentProblem.root+"?root=incident",
      label: t('INCIDENT'),
    },
    {
      route: currentLangPath + AvailableRoutes.help,
      label: t('HELP'),
    },
  ]

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row justify='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          {pspRoutes.map((pspRoute) => (
            <ButtonSelector
              key={pspRoute.route}
              type='button'
              isActive={formik.values.nextRoute === pspRoute.route}
              onClick={() => {
                formik.setFieldValue('nextRoute', pspRoute.route)
              }}
            >
              {pspRoute.label}
            </ButtonSelector>
          ))}
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs='content'>
          <ButtonPrimary type='submit' disabled={!formik.values.nextRoute}>
            {t('NEXT')}
          </ButtonPrimary>
        </Col>
      </Row>

      &nbsp;

      <Row justify='center'>
        <Col xs='content'>
            <SimpleLink
              label={t('I AM A CUSTODIAN')}
              url={process.env.REACT_APP_CUSTODIAN_LINK}
              target='_self'
            />
        </Col>
      </Row>
    </form>
  )
}

export default HomeInput
