import React from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'

import { SimpleModal } from 'pyrexx-react-library'
import { StyledButton, StyledSimpleNote } from './ModalPopup.styles'
import { useTranslation } from 'react-i18next'

const ModalPopup = (props) => {
  const {
    isOpen = true,
    handleModalClose,
    showButtonClose = true,
    noteText,
    onClick,
    buttonText,
  } = props

  const { t } = useTranslation()

  return (
    <SimpleModal
      isOpen={isOpen}
      handleModalClose={handleModalClose}
      showButtonClose={showButtonClose}
    >
      <Container>
        <Row justify='center'>
          <Col xs='content'>
            <StyledSimpleNote>{t('NOTICE')}</StyledSimpleNote>
          </Col>
        </Row>
        <Row>
          <Col>{noteText}</Col>
        </Row>
        <Row justify='center'>
          <Col xs='content'>
            <StyledButton onClick={onClick}>{buttonText}</StyledButton>
          </Col>
        </Row>
      </Container>
    </SimpleModal>
  )
}

ModalPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  noteText: PropTypes.any.isRequired,
  buttonText: PropTypes.string.isRequired,
}

export default ModalPopup
