import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { ScreenClassProvider, setConfiguration } from 'react-grid-system'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { HelmetProvider } from 'react-helmet-async'

import App from './components/App'
import { LoadingSpinner, portalTheme } from 'pyrexx-react-library'

import i18 from './i18'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { AppProvider } from './contexts/AppProvider'

setConfiguration(portalTheme.grid)

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
})
const queryClient = new QueryClient()

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StrictMode>
    <HelmetProvider>
      <I18nextProvider i18n={i18}>
        <BrowserRouter>
          <ScreenClassProvider>
            <ApolloProvider client={client}>
              <QueryClientProvider client={queryClient}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AppProvider>
                    <App />
                  </AppProvider>
                </Suspense>
              </QueryClientProvider>
            </ApolloProvider>
          </ScreenClassProvider>
        </BrowserRouter>
      </I18nextProvider>
    </HelmetProvider>
  </StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
