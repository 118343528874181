import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AppointmentSelection from '../../../../components/Steps/Inputs/AppointmentSelection'
import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'

import { AvailableRoutes } from '../../../../configs/RouteConfig'

const Start = (props) => {
  const { prevStep, nextStep, saveFormInputs, savedValues } = props

  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <StepContainer
      title={t('APPOINTMENT PLANNING')}
      description={
        <>
          {t('ARE YOU UNABLE TO KEEP YOUR APPOINTMENT?')}
          <br />
          {t('FOR WHICH SERVICE?')}
        </>
      }
      subtitle=''
      form={
        <AppointmentSelection
          prevStep={prevStep}
          nextStep={nextStep}
          savedValues={savedValues}
          saveFormInputs={saveFormInputs}
        />
      }
    />
  )
}

Start.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default Start
