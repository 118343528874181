import { format } from 'date-fns'
import { de, enGB } from 'date-fns/locale'

const locales = {
  en: enGB,
  de,
}

const formatDateLocale = (langString, dateInput, formatStr) => {
  if (!dateInput) return ''

  try {
    let date
    if (typeof dateInput === 'string' || dateInput instanceof String) {
      const [year, month, day, hour, minutes] = dateInput.split(/\D/)

      date = new Date(
        parseInt(year),
        parseInt(month) - 1, // because it needs to be 0 indexed. check documentation
        parseInt(day),
        parseInt(hour),
        parseInt(minutes),
      )
    } else {
      date = dateInput
    }

    return format(date, formatStr, {
      locale: locales[langString],
    })
  } catch (e) {
    console.error(e)
    return ''
  }
}

export default formatDateLocale
