import React from 'react'
import { Row } from 'react-grid-system'

import LinksFooter from './LinkFooter/LinksFooter'
import SocialFooter from './SocialFooter/SocialFooter'
import CopyrightFooter from './CopyrightFooter/CopyrightFooter'

import { StyledLinkFooter } from './LinkFooter/LinksFooter.styles'
import { StyledSocialFooter } from './SocialFooter/SocialFooter.styles'
import { StyledCopyrightFooter } from './CopyrightFooter/CopyrightFooter.styles'

const Footer = () => {
  return (
    <Row component='footer'>
      <StyledLinkFooter xs={12}>
        <LinksFooter />
      </StyledLinkFooter>

      <StyledSocialFooter xs={12}>
        <SocialFooter />
      </StyledSocialFooter>

      <StyledCopyrightFooter xs={12}>
        <CopyrightFooter />
      </StyledCopyrightFooter>
    </Row>
  )
}

export default Footer
