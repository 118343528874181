import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { StyledInput } from 'pyrexx-react-library'
import Navigator from '../../Navigator/Navigator'

const LocationInput = (props) => {
  const {
    savedValues = {},
    saveFormInputs,
    prevStep,
    nextStep,
    renderFloor = true,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const schema = Yup.object({
    street: Yup.string().required(t('IS REQUIRED')),
    floor: Yup.string().required(t('IS REQUIRED')),
    zip: Yup.string().required(t('IS REQUIRED')),
    town: Yup.string().required(t('IS REQUIRED')),
  })

  const formik = useFormik({
    initialValues: {
      street: savedValues.street || '',
      floor: savedValues.floor || '',
      zip: savedValues.zip || '',
      town: savedValues.town || '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      saveFormInputs(values)
      navigate(nextStep)
    },
    validateOnMount: true,
  })

  return (
    <form onSubmit={formik.handleSubmit} style={{ marginTop: '0' }}>
      <Row justify='center'>
        <Col xs={12} md={6}>
          <StyledInput
            type='input'
            name='street'
            id='street'
            label={t('STREET')}
            onChange={formik.handleChange}
            value={formik.values.street}
            feedbackMessage={formik.errors.street && formik.touched.street}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />

          {renderFloor && (
            <StyledInput
              type='input'
              name='floor'
              id='floor'
              label={t('FLOOR')}
              onChange={formik.handleChange}
              value={formik.values.floor}
              feedbackMessage={formik.errors.floor && formik.touched.floor}
              feedbackStyle='invalid'
              setFieldValue={formik.setFieldValue}
            />
          )}
        </Col>

        <Col xs={12} md={6}>
          <StyledInput
            type='input'
            name='zip'
            id='zip'
            label={t('ZIPCODE')}
            onChange={formik.handleChange}
            value={formik.values.zip}
            feedbackMessage={formik.errors.zip && formik.touched.zip}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />

          <StyledInput
            type='input'
            name='town'
            id='town'
            label={t('TOWN')}
            onChange={formik.handleChange}
            value={formik.values.town}
            feedbackMessage={formik.errors.town && formik.touched.town}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Navigator prevStep={prevStep} isNextButtonDisabled={!formik.isValid} />
    </form>
  )
}

LocationInput.propTypes = {
  savedValues: PropTypes.object,
  saveFormInputs: PropTypes.func.isRequired,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  renderFloor: PropTypes.bool,
}

export default LocationInput
