import React, { useEffect } from 'react'

import { CHAT_OPTIONS } from '../configs/config'
import { useAppContext } from '../contexts/AppContext'

const useZammadChat = () => {
  const { chat, setChat } = useAppContext()

  useEffect(() => {
    // @ts-ignore
    let newChat = null

    if (!chat) {
      try {
        // @ts-ignore
        newChat = new ZammadChat(CHAT_OPTIONS)
        setChat(newChat)
      } catch (e) {
        setChat(null)
      }
    } else {
      console.log('chat was initialized already')
    }

    return () => {
      // @ts-ignore
      newChat?.destroy()
    }
  }, [])

  return chat
}

export default useZammadChat
