import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import FaqList from './FaqList'
import { LoadingWrapper } from '../LoadingWrapper'

const GET_FAQ_ITEMS = gql`
  query FaqListContainerQuery($lang: String) {
    getFaqItems(portal: "psp", lang: $lang) {
      categories {
        id
        name
        questions {
          id
          question
          answer
        }
      }
    }
  }
`

const FaqListContainer = (props) => {
  const { currentLanguage } = props

  const variables = { lang: currentLanguage }

  const { data, loading, error } = useQuery(GET_FAQ_ITEMS, { variables })

  const categories = data?.getFaqItems?.categories || []

  return (
    <LoadingWrapper loading={loading} error={error}>
      <FaqList categories={categories} />
    </LoadingWrapper>
  )
}

FaqListContainer.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
}

export default FaqListContainer
