import React from 'react'
import { useTranslation } from 'react-i18next'

const RealtimeUpdates = ({ osssStatus }: { osssStatus: string }) => {
  const { t } = useTranslation()

  return (
    <div>
      <h3>{t('OSSS STATUS')}</h3>
      <p>{osssStatus}</p>
    </div>
  )
}

export default RealtimeUpdates
