import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Col, Row } from 'react-grid-system'

import { ButtonSecondary, ButtonSelector } from 'pyrexx-react-library'
import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'

import { AvailableRoutes } from '../../../../configs/RouteConfig'

const PreStart = (props) => {
  const { prevStep, nextStep, saveFormInputs, currentLanguage } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const schema = Yup.object({
    appointmentPlanningChoice: Yup.number().required(t('CHOOSE ONE')),
  })

  const formik = useFormik({
    initialValues: {
      appointmentPlanningChoice: undefined,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (values.appointmentPlanningChoice === 1) {
        navigate(nextStep)
      } else {
        // SPECIAL CONDITION
        navigate(
          '/' + currentLanguage + '/' + AvailableRoutes.appointmentProblem.root,
        )
      }
    },
  })

  const appointmentPlanningChoices = [
    {
      appointmentPlanningChoice: 1,
      label: t('CAN NOT KEEP AN APPOINTMENT'),
    },
    {
      appointmentPlanningChoice: 2,
      label: t('I HAVE A PROBLEM WITH A SMOKE DETECTOR'),
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <StepContainer
      prevStep={prevStep}
      showBackArrow
      title={t('APPOINTMENT PLANNING')}
      description={t(
        'YOU CANNOT KEEP YOUR APPOINTMENT OR YOU HAVE A PROBLEM WITH A SMOKE DETECTOR?',
      )}
      subtitle=''
      form={
        <form onSubmit={formik.handleSubmit}>
          <Row justify='center'>
            <Col xs={12} sm={10} md={8} lg={6}>
              {appointmentPlanningChoices.map((choice) => (
                <ButtonSelector
                  key={
                    'appointment-planning-' + choice.appointmentPlanningChoice
                  }
                  type='button'
                  isActive={
                    formik.values.appointmentPlanningChoice ===
                    choice.appointmentPlanningChoice
                  }
                  onClick={() => {
                    formik.setFieldValue(
                      'appointmentPlanningChoice',
                      choice.appointmentPlanningChoice,
                    )
                  }}
                >
                  {choice.label}
                </ButtonSelector>
              ))}
            </Col>
          </Row>

          <Row align='center' justify='center'>
            <Col align='center'>
              <ButtonSecondary
                type='submit'
                disabled={!formik.values.appointmentPlanningChoice}
              >
                {t('NEXT')}
              </ButtonSecondary>
            </Col>
          </Row>
        </form>
      }
    />
  )
}

PreStart.propTypes = {
  nextStep: PropTypes.string,
  saveFormInputs: PropTypes.func.isRequired,
}

export default PreStart
