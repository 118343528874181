import { format, parseISO } from 'date-fns'

// https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/master/services/google.md
const formatStr = "yyyyMMdd'T'HHmmSS"

const generateEventUrl = (eventData = {}) => {
  const {
    title = 'Your Pyrexx Appointment',
    from = new Date(Date.now()).toISOString(),
    to = new Date(Date.now()).toISOString(),
    street = '',
    zip = '',
    town = '',
    t = (v) => v,
  } = eventData

  const googleCalendarUrl = 'https://calendar.google.com/calendar/r/eventedit'
  const sf = true
  const output = 'xml'

  const formattedTitle = title.split(' ').join('+')
  const formattedFrom = format(parseISO(from), formatStr)
  const formattedTo = format(parseISO(to), formatStr)
  const formattedDate = `${formattedFrom}/${formattedTo}`
  const details =
    t('FOR MORE DETAILS').split(' ').join('+') +
    ':+' +
    '<a href="https://www.pyrexx.com" target="_blank" rel="nofollow">www.pyrexx.com</a>'
  const formattedLocation = `${street}, ${zip} ${town}`.split(' ').join('+')

  return `${googleCalendarUrl}?text=${formattedTitle}&dates=${formattedDate}&details=${details}&location=${formattedLocation}&sf=${sf}&output=${output}`
}

export default generateEventUrl
