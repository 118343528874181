import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-grid-system'

const CopyrightFooter = () => {
  const { t } = useTranslation()

  return (
    <Container xs sm md>
      <Row align='center'>
        <Col component='span' xs={12}>
          {t('PYREXX - SIMPLE, CLEAN, SAFE')}
        </Col>
      </Row>
    </Container>
  )
}

export default CopyrightFooter
