import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row, Visible } from 'react-grid-system'
import { Link } from 'react-router-dom'

import { RouteLink, SimpleLink, SimpleList } from 'pyrexx-react-library'
import MobilePullDown from '../../MobilePullDown/MobilePullDown'

import { AvailableRoutes } from '../../../configs/RouteConfig'
import { AvailableLinks } from '../../../configs/LinkConfig'
import { useAppContext } from '../../../contexts/AppContext'

const LinksFooter = () => {
  const { t } = useTranslation()
  const { currentLangPath } = useAppContext()

  const portalLinks = {
    label: t('SERVICE PORTAL'),
    links: [
      <RouteLink
        key='linkAppointment'
        navLinkElement={
          <Link to={currentLangPath + AvailableRoutes.appointment}>
            {t('APPOINTMENT')}
          </Link>
        }
      />,
      <RouteLink
        key='linkHelp'
        navLinkElement={
          <Link to={currentLangPath + AvailableRoutes.help}>
            {t('MORE HELP')}
          </Link>
        }
      />,
    ],
  }

  const supportLinks = {
    label: t('SUPPORT'),
    links: [
      <RouteLink
        key='linkContactInfo'
        navLinkElement={
          <Link to={currentLangPath + AvailableRoutes.contact}>
            {t('CONTACT')}
          </Link>
        }
      />,
    ],
  }

  const newsletterLinks = {
    label: t('NEWSLETTER'),
    links: [
      <RouteLink
        key='linkRegisterNewsletter'
        navLinkElement={
          <Link to={currentLangPath + AvailableRoutes.newsletter}>
            {t('NEWSLETTER REGISTRATION')}
          </Link>
        }
      />,
    ],
  }

  const pyrexxLinks = {
    label: t('PYREXX GMBH'),
    links: [
      <SimpleLink
        key='linkAboutUs'
        label={t('ABOUT US')}
        url={AvailableLinks.aboutUs}
        target='_blank'
      />,
      <SimpleLink
        key='linkPyrexxHomePage'
        label='pyrexx.com'
        url={AvailableLinks.pyrexx}
        target='_blank'
      />,
      <RouteLink
        key='linkDataPrivacy'
        navLinkElement={
          <Link to={currentLangPath + AvailableRoutes.privacy}>
            {t('DATA PRIVACY')}
          </Link>
        }
      />,
      <RouteLink
        key='linkImprint'
        navLinkElement={
          <Link to={currentLangPath + AvailableRoutes.imprint}>
            {t('IMPRINT')}
          </Link>
        }
      />,
    ],
  }

  return (
    <Container xs sm md>
      <Row>
        <Col md={12} lg={3}>
          <Visible lg xl>
            <SimpleList label={portalLinks.label} values={portalLinks.links} />
          </Visible>

          <Visible xs sm md>
            <MobilePullDown
              key='mobilePullDown-portalLinks'
              headerLabel={portalLinks.label}
              values={portalLinks.links}
            />
          </Visible>
        </Col>

        <Col md={12} lg={3}>
          <Visible lg xl>
            <SimpleList
              label={supportLinks.label}
              values={supportLinks.links}
            />
          </Visible>

          <Visible xs sm md>
            <MobilePullDown
              key='mobilePullDown-supportLinks'
              headerLabel={supportLinks.label}
              values={supportLinks.links}
            />
          </Visible>
        </Col>

        <Col md={12} lg={3}>
          <Visible lg xl>
            <SimpleList
              label={newsletterLinks.label}
              values={newsletterLinks.links}
            />
          </Visible>

          <Visible xs sm md>
            <MobilePullDown
              key='mobilePullDown-newsletterLinks'
              headerLabel={newsletterLinks.label}
              values={newsletterLinks.links}
            />
          </Visible>
        </Col>

        <Col md={12} lg={3}>
          <Visible lg xl>
            <SimpleList label={pyrexxLinks.label} values={pyrexxLinks.links} />
          </Visible>

          <Visible xs sm md>
            <MobilePullDown
              key='mobilePullDown-pyrexxLinks'
              headerLabel={pyrexxLinks.label}
              values={pyrexxLinks.links}
            />
          </Visible>
        </Col>
      </Row>
    </Container>
  )
}

export default LinksFooter
