import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SlotList from '../../Slot/SlotList'
import Navigator from '../../Navigator/Navigator'

const SlotSelectionInput = (props) => {
  const {
    savedValues = {},
    saveFormInputs,
    prevStep,
    nextStep,
    slots,
    validatedEncryptedApartmentId,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  // TODO REMOVE NULLABLE CONDITIONS (WHY??)
  const schema = Yup.object({
    validatedEncryptedApartmentId: Yup.string().nullable(true), // ??
    timeframeFrom: Yup.string().nullable(true),
    timeframeTo: Yup.string().nullable(true),
    currentSlot: Yup.string().nullable(true),
    isUserAvailable: Yup.bool(),
  })

  const formik = useFormik({
    initialValues: {
      validatedEncryptedApartmentId: '',
      timeframeFrom: '',
      timeframeTo: '',
      currentSlot: savedValues.currentSlot || null,
      isUserAvailable: savedValues.isUserAvailable || true,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      saveFormInputs(values)
      navigate(nextStep)
    },
  })

  const handleSlotSelection = (id, from, to, validatedEncryptedApartmentId) => {
    const slotId = id.split('_')[1];

    formik.setFieldValue(
      'validatedEncryptedApartmentId',
      validatedEncryptedApartmentId,
    )
    formik.setFieldValue('timeframeFrom', slots[slotId].from)
    formik.setFieldValue('timeframeTo', slots[slotId].to)
    formik.setFieldValue('currentSlot', id)
    formik.setFieldValue('isUserAvailable', true)
  }

  const handleUnavailability = (validatedEncryptedApartmentId) => {
    console.log(validatedEncryptedApartmentId)

    formik.setFieldValue(
      'validatedEncryptedApartmentId',
      validatedEncryptedApartmentId,
    )
    formik.setFieldValue('timeframeFrom', null)
    formik.setFieldValue('timeframeTo', null)
    formik.setFieldValue('currentSlot', null)
    formik.setFieldValue('isUserAvailable', false)
  }

  const emptySlotText =
    Array.isArray(slots) && slots.length > 0
      ? t('THERE IS NO RIGHT SLOT FOR ME, PLEASE CONTACT ME SOON.')
      : t(
          'UNFORTUNATELY WE COULD NOT FIND FREE TIME FRAME. WE WILL CONTACT YOU SOON.',
        )

  return (
    <form onSubmit={formik.handleSubmit}>
      <SlotList
        slots={slots}
        validatedEncryptedApartmentId={validatedEncryptedApartmentId}
        handleSlotSelection={handleSlotSelection}
        handleUnavailability={handleUnavailability}
        currentSlot={formik.values.currentSlot}
        isUserAvailable={formik.values.isUserAvailable}
        emptySlotText={emptySlotText}
      />

      <Navigator prevStep={prevStep} />
    </form>
  )
}

SlotSelectionInput.propTypes = {
  savedValues: PropTypes.object,
  saveFormInputs: PropTypes.func.isRequired,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  slots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      date: PropTypes.string,
      day: PropTypes.string,
      hour: PropTypes.string,
    }),
  ),
  validatedEncryptedApartmentId: PropTypes.string,
}

export default SlotSelectionInput
