import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Spinner } from 'pyrexx-react-library'
import RateUsList from './RateUsList'

const GET_RATE_US_ITEMS = gql`
  query RateUsListContainerQuery($lang: String) {
    getRateUsItems(portal: "psp", lang: $lang) {
      categories {
        id
        name
        questions {
          id
          name
        }
      }
    }
  }
`

const RateUsListContainer = (props) => {
  const { currentLanguage } = props

  const { t } = useTranslation()

  const variables = { lang: currentLanguage }
  const { data, loading, error } = useQuery(GET_RATE_US_ITEMS, { variables })

  if (loading) return <Spinner />
  if (error)
    return (
      <p>
        {t(
          'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
        )}
      </p>
    )

  const categories = data?.getRateUsItems?.categories || []

  return <RateUsList categories={categories} />
}

RateUsListContainer.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
}

export default RateUsListContainer
