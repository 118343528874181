export const CUSTOMER_SERVICES_DEPARTMENT = 'CustomerServices'

export const CHAT_OPTIONS = {
  host: process.env.REACT_APP_ZAMMAD_CHAT_HOST,
  title: 'PSP CHAT',
  background: '#E8221B',
  fontSize: '14px',
  flat: true,
  chatId: process.env.REACT_APP_ZAMMAD_CHAT_ID || 1,
  show: false,
  debug: false,
}
