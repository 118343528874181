import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { SimplePullDownStyled } from './RateUs.styles'
import isValid from '../../helper/isValid'
import RateUsInput from './RateUsInput'

const RateUsList = (props) => {
  const { categories = [] } = props

  const { t } = useTranslation()

  return isValid(categories) ? (
    categories.map((category) => (
      <SimplePullDownStyled
        key={category?.id}
        headerLabelElement={category?.name}
        values={<RateUsInput questions={category?.questions} />}
      />
    ))
  ) : (
    <p>{t('NO AVAILABLE CATEGORIES')}</p>
  )
}

RateUsList.propTypes = {
  categories: PropTypes.array.isRequired,
}

export default RateUsList
