import { css } from 'styled-components'

export const toggleSwitchWithLabelTheme = {
  components: {
    toggleSwitchWithLabel: {
      simpleLabel: {
        additionalStyles: css`
          height: 49px;
        `,
      },
    },
  },
}
