import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import {
  Icon,
  LoadingSpinner,
  portalTheme,
  SimpleNote,
} from 'pyrexx-react-library'
import TrackingItemPullDown from '../../TrackingItemPullDown/TrackingItemPullDown'
import ModalPopup from '../../ModalPopup/ModalPopup'
import DeleteButton from '../../DeleteButton/DeleteButton'

import { AvailableRoutes } from '../../../configs/RouteConfig'
import isValid from '../../../helper/isValid'
import showStatusColor from '../../../helper/generateStatusColor'
import { DELETE_TRACKING_TICKET } from '../../../mutations/DeleteTrackingTicketMutation'

import {
  TimelineRow,
  EventRow,
  EventIconCol,
} from './TrackingList.styles'

import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TrackingList = (props) => {
  const {
    savedValues = {},
    trackingItems,
    trackingStatus,
    currentLanguage,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: '',
    message: '',
  })

  const [deleteTrackingTicket] = useMutation(DELETE_TRACKING_TICKET)

  const handleDelete = () => {
    setDeleteStatus((prevState) => ({ ...prevState, loading: true }))

    deleteTrackingTicket({
      variables: { ticketId: savedValues.trackingId },
      onCompleted: (data) => {
        if (data.deleteAppointmentTracking.success) {
          setDeleteStatus({
            loading: false,
            status: 'success',
            message: t('TRACKING TICKET SUCCESSFULLY DELETED'),
          })

          setTimeout(() => {
            navigate(AvailableRoutes.appointmentTracking.root)
          }, 3000)
        } else {
          setDeleteStatus({
            loading: false,
            status: 'error',
            message: t(
              'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
            ),
          })
        }
      },
      onError: () => {
        setDeleteStatus({
          loading: false,
          status: 'error',
          message: t(
            'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
          ),
        })
      },
    })
  }

  return (
    <>
      {deleteStatus.loading && <LoadingSpinner />}

      <ModalPopup
        isOpen={showDeleteModal}
        noteText={
          <div>
            <p>{t('DELETE TRACKING TICKET?')}</p>

            {deleteStatus.status && (
              <SimpleNote
                noteStatus={deleteStatus.status}
                text={deleteStatus.message}
              />
            )}
          </div>
        }
        handleModalClose={() => {
          setShowDeleteModal(false)
        }}
        onClick={handleDelete}
        buttonText={t('DELETE')}
      />

      <Row justify='center' nogutter style={{marginBottom: '1rem'}}>
        <Col xs='content'>
          <Icon
            icon='radio-button-checked'
            size='25px'
            color={showStatusColor(trackingStatus)}
          />
        </Col>
        <Col xs='content' style={{marginLeft: '5px'}}>{t(trackingStatus)}</Col>
      </Row>

      <Row justify='center'>
        <Col xs='content'>
          <DeleteButton
            onClick={() => {
              setShowDeleteModal(true)
            }}
          />
        </Col>
      </Row>
      <TimelineRow>
      {isValid(trackingItems) ? (
        trackingItems.map((item, index) => {
          return (
            <EventRow>
              <EventIconCol className="event_icon_column" style={{flexGrow: 0, paddingTop: "calc(16px + 0.5rem)", width: "1px", marginRight: '1rem'}}>
                <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
              </EventIconCol>
              <Col style={{backgroundColor: 'whitesmoke', marginBottom: '1rem', borderRadius: "3px", paddingLeft: 0}}>
                <TrackingItemPullDown
                  label={item?.name}
                  date={(new Date(item.dateTime)).toLocaleString(currentLanguage)}
                  eventData={item.eventData}
                />
              </Col>
            </EventRow>
          )
        })
      ) : (
        <div>{t('REFERENCE NUMBER NOT FOUND')}</div>
      )}
      </TimelineRow>
    </>
  )
}

TrackingList.propTypes = {
  trackingItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  trackingStatus: PropTypes.string.isRequired,
}

export default TrackingList
