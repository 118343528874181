import styled from 'styled-components'
import { Container } from 'react-grid-system'

import { portalTheme } from 'pyrexx-react-library'

export const StyledContainer = styled(Container)`
  background-color: ${portalTheme.color.basicColorWhite};
  height: 100%;
`

export const ImgStyled = styled.img`
  width: 100%;
  margin-top:2rem;
  margin-bottom: 2rem;
`

export const TextStyled = styled.h2`
  font-size: ${portalTheme.font.size.headingSmall};
  text-align: center;
  margin-bottom: 2rem;
  color: ${portalTheme.color.basicColorBlack};
`
