import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'

import ContactForm from './ContactForm'
import ContactPullDown from '../../components/ContactPullDown/ContactPullDown'

import contactImage from '../../assets/contact.png'
import { StyledPersonalCareContainer } from './Contact.styles'

import { AvailableRoutes } from '../../configs/RouteConfig'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faCommentDots, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { portalTheme } from 'pyrexx-react-library'

const ContactContainer = () => {
  const { t, i18n } = useTranslation()

  const contactChatLinkStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 0,
    alignItems: 'center',
    fontFamily: portalTheme.font.family.quicksand,
    fontSize: portalTheme.font.size.headingSmall,
    fontWeight: portalTheme.font.weight.bold,
    textDecoration: 'none',
    color: portalTheme.color.basicColorBlack,
    maxWidth: '100%',
    width: '100%',
  };

  return (
    <>
      <ContactPullDown
        label={
          <>
            <ContactIcon icon={faEnvelope} />
            {t('EMAIL')}
          </>
        }
        element={<ContactForm />}
      />

      <ContactPullDown
        label={
          <>
            <ContactIcon icon={faPhone} />
            {t('INHABITANT SERVICE')}
          </>
        }
        element={
          <StyledPersonalCareContainer align='center'>
            <Col xs={6} style={{ padding: '1rem' }}>
              <img alt='Contact' src={contactImage} />
            </Col>

            <Col xs={6} align='center'>
              <div>{t('SERVICE TELEPHONE NUMBER')}</div>
              <div>+49 30 74 74 74 74</div>
            </Col>
          </StyledPersonalCareContainer>
        }
      />

      <Row
        justify='between'
        align='center'
        style={{
          padding: '15px',
          paddingBlockStart: '20px', // to have the same size gap as the two items above. this is only necessary because the custom hide/expand SVGs have so much padding
        }}
        nogutter
      >
        <Link
          to={'../' + AvailableRoutes.livechat}
          style={contactChatLinkStyle}
        >
          <ContactIcon icon={faCommentDots} />
          <Col style={{flexGrow: 1, padding:0 }}>
            {t('CHAT WITH US VIA THE PYREXX CHAT')}
          </Col>
          <FontAwesomeIcon icon={faChevronRight} style={{marginInlineStart: '1rem'}}/>
        </Link>
      </Row>
    </>
  )
}

const ContactIcon = ({icon}) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      fixedWidth={true}
      size='lg'
      style={{marginInlineEnd: '1rem'}}
    />
  );
}

export default ContactContainer
