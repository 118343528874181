import React from 'react'
import PropTypes from 'prop-types'

import MobileMenuRouteLink from './MobileMenuRouteLink'

const MobileMenuListTitle = (props) => {
  const { title = {}, handleMobileMenu } = props

  return (
    <h4>
      <MobileMenuRouteLink
        linkTo={title.link}
        handleMobileMenu={handleMobileMenu}
        type='title'
      >
        {title.label}
      </MobileMenuRouteLink>
    </h4>
  )
}

MobileMenuListTitle.propTypes = {
  title: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  handleMobileMenu: PropTypes.func.isRequired,
}

export default MobileMenuListTitle
