import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { ButtonPrimary, portalTheme } from 'pyrexx-react-library'

import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'

const NotFound = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()

  return (
    <Container>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_NOT_FOUND')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_NOT_FOUND')}
        />
      </Helmet>

      <Main>404</Main>
      <Description>{t('WE COULD NOT FIND THIS PAGE')}</Description>

      <Link to='/'>
        <ButtonPrimary>{t('BACK HOME')}</ButtonPrimary>
      </Link>
    </Container>
  )
}

export default NotFound

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 2rem;
`

const Main = styled.p`
  font-size: 72px;
  font-family: ${portalTheme.font.family.opensans};
  font-weight: ${portalTheme.font.weight.bold};

  text-align: center;
  margin: 0rem 0;
`

const Description = styled.p`
  font-size: 24px;
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.light};

  text-align: center;
  margin: 3rem 0;
`
