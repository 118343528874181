import styled from 'styled-components'
import { portalTheme, SimplePullDown } from 'pyrexx-react-library'

export const StyledContactPullDown = styled(SimplePullDown)`
  &:nth-child(n + 2) {
    padding-top: 1rem;
  }

  color: ${portalTheme.color.basicColorBlack};

  .simple-pull-down-label-container {
    padding-left: 1rem;
    border: 1px solid ${portalTheme.color.basicColorLightGrey2};
  }

  .simple-pull-down-values {
    padding-left: 1rem;
    padding-right: 1rem;
    display: none !important;

    &.open {
      display: flex !important;
    }

    margin-top: 1rem;
    border: 1px solid ${portalTheme.color.basicColorLightGrey2};
  }
`
