import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { ButtonSecondary, SimpleNote, StyledInput } from 'pyrexx-react-library'

import LivechatZammad from './LivechatZammad'

function LivechatForm(props) {
  const { availableTopics } = props

  const { t } = useTranslation()
  const [preQualificationStep, setPreQualificationStep] = useState(true)

  const schema = Yup.object({
    contactName: Yup.string().required(t('WE NEED A CONTACT NAME')),
    street: Yup.string(),
    houseNumber: Yup.string(),
    zip: Yup.string(),
    referenceNumber: Yup.number()
      .nullable(true)
      .transform((v, o) => (o === '' ? null : v))
      .typeError(t('PLEASE ENTER ONLY NUMBERS'))
      .integer(t('PLEASE PROVIDE NUMBER WITHOUT COMMA')),
    selectedTopic: Yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      contactName: '',
      street: '',
      houseNumber: '',
      zip: '',
      referenceNumber: '',
      selectedTopic: '',
    },
    validationSchema: schema,
    onSubmit: () => {
      if (preQualificationStep) {
        setPreQualificationStep(false)
      }
    },
  })

  const {
    contactName,
    street,
    houseNumber,
    zip,
    referenceNumber,
    selectedTopic,
  } = formik.values || {}

  // const name = contactName + '_PSP_CustomerServices'
  // const department = CUSTOMER_SERVICES_DEPARTMENT

  const fields = [
    { label: t('NAME'), value: contactName },
    { label: t('STREET'), value: street },
    { label: t('RESIDENCE NUMBER'), value: houseNumber },
    { label: t('ZIP'), value: zip },
    { label: t('REFERENCE NUMBER'), value: referenceNumber },
    {
      label: t('SELECTED TOPIC'),
      value: availableTopics.find((t) => t?.id === selectedTopic)?.label,
    },
  ]

  let msg = ''

  fields.forEach((field) => {
    if (field.value) {
      msg += `${field.label}: ${field.value}\n`
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledInput
        type='input'
        name='contactName'
        id='contactName'
        onChange={formik.handleChange}
        value={formik.values.contactName}
        label={t('NAME')}
        feedbackMessage={
          formik.touched.contactName && formik.errors.contactName
        }
        feedbackStyle='invalid'
        setFieldValue={formik.setFieldValue}
      />

      <StyledInput
        type='input'
        name='street'
        id='street'
        onChange={formik.handleChange}
        value={formik.values.street}
        label={t('STREET')}
        feedbackMessage={formik.touched.street && formik.errors.street}
        feedbackStyle='invalid'
        setFieldValue={formik.setFieldValue}
      />

      <StyledInput
        type='input'
        name='houseNumber'
        id='houseNumber'
        onChange={formik.handleChange}
        value={formik.values.houseNumber}
        label={t('RESIDENCE NUMBER')}
        feedbackMessage={
          formik.touched.houseNumber && formik.errors.houseNumber
        }
        feedbackStyle='invalid'
        setFieldValue={formik.setFieldValue}
      />

      <StyledInput
        type='input'
        name='zip'
        id='zip'
        onChange={formik.handleChange}
        value={formik.values.zip}
        label={t('ZIP')}
        feedbackMessage={formik.touched.zip && formik.errors.zip}
        feedbackStyle='invalid'
        setFieldValue={formik.setFieldValue}
      />

      <br />

      <StyledInput
        type='input'
        name='referenceNumber'
        id='referenceNumber'
        onChange={formik.handleChange}
        value={formik.values.referenceNumber}
        label={t('REFERENCE NUMBER')}
        feedbackMessage={
          formik.touched.referenceNumber && formik.errors.referenceNumber
        }
        feedbackStyle='invalid'
        setFieldValue={formik.setFieldValue}
      />
      <SimpleNote
        text={t('IF YOU DONT HAVE A REFERENCE NUMBER, CLICK “NEXT”.')}
        textStatus='warning'
      />

      {preQualificationStep ? (
        <ButtonRow>
          <ButtonSecondary type='submit'>{t('NEXT')}</ButtonSecondary>
        </ButtonRow>
      ) : (
        <div>
          <br />
          <StyledInput
            type='reactSelect'
            id='selectedTopic'
            name='selectedTopic'
            onChange={formik.handleChange}
            value={formik.values.selectedTopic}
            label={t('SELECT YOUR TOPIC OF CONVERSATION')}
            feedbackMessage={
              formik.touched.selectedTopic && formik.errors.selectedTopic
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
            reactSelectOptions={availableTopics.map((option) => {
              return {
                value: option.id,
                label: option.label,
              }
            })}
          />

          <ButtonRow>
            {formik.values.selectedTopic && <LivechatZammad msg={msg} />}
          </ButtonRow>
        </div>
      )}
    </form>
  )
}

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export { LivechatForm }
