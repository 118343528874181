import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'

import { Spinner } from 'pyrexx-react-library'
import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'
import TrackingList from '../../../../components/Steps/Results/TrackingList'
import ModalPopup from '../../../../components/ModalPopup/ModalPopup'

import isValid from '../../../../helper/isValid'
import { AvailableRoutes } from '../../../../configs/RouteConfig'

const GET_TRACKING_RESULTS = gql`
  query TrackingResultQuery($lang: String!, $trackingNumber: String!) {
    getAppointmentTracking(lang: $lang, trackingNumber: $trackingNumber) {
      events {
        eventData {
          dataType
          label
          value {
            status
            value
          }
        }
        dateTime
        name
      }
      status
    }
  }
`

const TrackingListContainer = ({
  currentLanguage = 'de',
  savedValues = {},
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const variables = {
    lang: currentLanguage,
    trackingNumber: savedValues?.trackingId,
  }

  const { data, loading, error } = useQuery(GET_TRACKING_RESULTS, {
    variables,
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Spinner />
  if (error)
    return (
      <p>
        {t(
          'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
        )}
      </p>
    )

  const { events = [], status = '' } = data?.getAppointmentTracking || {}

  return isValid(events) ? (
    <TrackingList
      trackingItems={events}
      trackingStatus={status}
      savedValues={savedValues}
      currentLanguage={currentLanguage}
    />
  ) : (
    <ModalPopup
      isOpen
      noteText={<p>{t('REFERENCE NUMBER NOT FOUND')}</p>}
      showButtonClose={false}
      handleModalClose={undefined}
      onClick={() => {
        navigate(AvailableRoutes.appointmentTracking.root)
      }}
      buttonText={t('OK')}
    />
  )
}

const TrackingResult = (props) => {
  const { prevStep, savedValues, currentLanguage } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!savedValues?.trackingId) {
      navigate('../../' + AvailableRoutes.appointmentTracking.root)
    }
  }, [savedValues.trackingId])

  return (
    <StepContainer
      prevStep={prevStep}
      showBackArrow
      title={t('APPOINTMENT TRACKING')}
      description={t('TRACKING TICKET')}
      subtitle={savedValues.trackingId}
      form={
        <TrackingListContainer
          currentLanguage={currentLanguage}
          savedValues={savedValues}
        />
      }
    />
  )
}

export default TrackingResult
