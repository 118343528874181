import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'

import { portalTheme, SimplePullDown } from 'pyrexx-react-library'

const RowStyled = styled(Row)`
  margin-top: 1rem;
`

const LeftCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 575px) {
    justify-content: center;
  }
`

const RightCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 575px) {
    justify-content: center;
  }
`

const SimplePullDownStyled = styled(SimplePullDown)`
  user-select: unset;

  color: ${portalTheme.color.basicColorBlack};
  font-size: ${portalTheme.font.size.bodyRegular};
  font-weight: ${portalTheme.font.weight.regular};
  text-align: left;

  border: 2px solid;
  border-color: ${portalTheme.color.linkColorSecondary};

  margin-bottom: 0.75rem;
`

const TextStyled = styled.label`
  font-family: ${portalTheme.font.family.quicksand};
  color: ${portalTheme.color.basicColorBlack};
  font-size: ${portalTheme.font.size.bodyRegular};
  font-weight: ${portalTheme.font.weight.regular};

  text-align: right;
  margin: 0.5rem 0;

  @media screen and (max-width: 575px) {
    text-align: center;
  }
`

const TextAreaStyled = styled.textarea`
  font-family: ${portalTheme.font.family.quicksand};
  font-size: ${portalTheme.font.size.bodyRegular};
  font-weight: ${portalTheme.font.weight.regular};

  background-color: ${portalTheme.color.basicColorWhite};
  border: 1px solid;
  border-color: ${portalTheme.color.linkColorSecondary};

  width: 100%;
  height: 6rem;
`

export {
  RowStyled,
  LeftCol,
  RightCol,
  SimplePullDownStyled,
  TextStyled,
  TextAreaStyled,
}
