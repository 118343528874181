import React from 'react'
import { Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ButtonSecondary } from 'pyrexx-react-library'

const SaveButton = ({ isLoading }) => {
  const { t } = useTranslation()

  return (
    <Row justify='center' style={{ margin: '1rem 0' }}>
      <ButtonSecondary type='submit' isLoading={isLoading}>
        {t('SAVE')}
      </ButtonSecondary>
    </Row>
  )
}

export default SaveButton
