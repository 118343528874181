import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { CookieBanner, hasCookie } from 'pyrexx-react-library'
import { AvailableRoutes } from '../../configs/RouteConfig'
import GA from '../../utils/GoogleAnalytics'

const BANNER_PREFIX = 'pspPortal'

const PSPCookieBanner = () => {
  const firstCall = {
    req: false,
    pref: false,
    stats: false,
  }

  const { t } = useTranslation()
  const location = useLocation()

  const [cookies, setCookies] = useState(() => ({
    req: hasCookie('consent', BANNER_PREFIX),
    pref: hasCookie('preferences', BANNER_PREFIX),
    stats: hasCookie('statistics', BANNER_PREFIX),
  }))

  useEffect(() => {
    cookies.stats &&
      GA.init(cookies.stats) &&
      GA.logPageChange(location.pathname, location.search)
  }, [cookies.stats, location.pathname, location.search])

  return (
    <CookieBanner
      bannerPrefix={BANNER_PREFIX}
      checkboxHeaderLabel={t('I CONSENT TO THE FOLLOWING COOKIES') + ' :'}
      message={t(
        'WE WANT TO USE SOME COOKIES TO HELP MAKING THE BEST POSSIBLE EXPERIENCE FOR YOU.' +
          'THE NECESSARY COOKIES SIMPLY SAVE YOUR CONSENT SETTING, NOTHING ELSE.' +
          'THE STATISTIC COOKIES HELP US GETTING SOME INSIGHT INTO YOUR USAGE OF OUR QUOTATION PORTAL, THE USABILITY COOKIES SAVE YOUR CONTACT DATA SO THAT YOU' +
          'DONT HAVE TO RETYPE THEM EVERY TIME. FOR MORE DETAILS SEE OUR PRIVACY POLICY',
      )}
      declineButtonText={t('NO COOKIES PLEASE')}
      acceptButtonText={t('I CONSENT')}
      statisticsOptionText={t('STATISTICAL')}
      preferencesOptionText={t('USABILITY')}
      necessaryOptionText={t('NECESSARY')}
      showDeclineButton
      privacyPolicyLinkText={t('DATA PRIVACY')}
      policyLink={AvailableRoutes.privacy}
      onAccept={() => {
        if (firstCall.req) {
          firstCall.req = false

          setCookies((prev) => ({
            ...prev,
            req: true,
          }))
        }
      }}
      onAcceptPreferences={() => {
        if (firstCall.pref) {
          firstCall.pref = false
          setCookies((prev) => ({
            ...prev,
            pref: true,
          }))
        }
      }}
      onAcceptStatistics={() => {
        if (firstCall.stats) {
          firstCall.stats = false

          setCookies((prev) => ({
            ...prev,
            stats: true,
          }))
        }
      }}
      clickOnConfirm={() => {
        setCookies({
          req: hasCookie('consent', BANNER_PREFIX),
          pref: hasCookie('preferences', BANNER_PREFIX),
          stats: hasCookie('statistics', BANNER_PREFIX),
        })
      }}
      showMarketingOption={false}
    />
  )
}

export default PSPCookieBanner
