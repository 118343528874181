import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as BaseNavLink } from 'react-router-dom'
import { Col, Container, Row, Visible } from 'react-grid-system'

import {
  ButtonFlag,
  LangSwitcherModal,
  PageSwitcher,
  RouteLink,
} from 'pyrexx-react-library'
import MobileMenu from '../../MobileMenu/MobileMenu'

import { Burger, ImgStyled, LanguageModalWrapper } from './PspHeader.styles'
import pyrexxLogo from '../../../assets/pyrexx_logo.svg'
import { AvailableRoutes } from '../../../configs/RouteConfig'
import { availableLanguages } from '../../../configs/languageConfig'
import { useAppContext } from '../../../contexts/AppContext'

const navStyles = {
  desktop: {
    className: 'navigation-menu',
    addActiveClassName: 'navigation-menu-active',
  },
  mobile: {
    className: 'link-decoration link-color-primary',
    addActiveClassName: '',
  },
}

const PspHeader = () => {
  const { t, i18n } = useTranslation()
  const { currentLanguage, currentLangPath } = useAppContext()

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const handleMobileMenu = (e) => setIsMobileMenuOpen(e)

  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false)
  const handleLanguageModalOpen = () => setIsLanguageModalOpen(true)
  const handleLanguageModalClose = () => setIsLanguageModalOpen(false)

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  const lists = [
    {
      title: {
        label: t('APPOINTMENT'),
        link: currentLangPath + AvailableRoutes.appointment,
      },
      links: [
        {
          label: t('APPOINTMENT MISSED'),
          link: currentLangPath + AvailableRoutes.appointmentMissed.root,
        },
        {
          label: t('APPOINTMENT PLANNING'),
          link: currentLangPath + AvailableRoutes.appointmentPlanning.root,
        },
        {
          label: t('APPOINTMENT CHECK'),
          link: currentLangPath + AvailableRoutes.appointmentCheck.root,
        },
        {
          label: t('APPOINTMENT TRACKING'),
          link: currentLangPath + AvailableRoutes.appointmentTracking.root,
        },
      ],
    },
    {
      title: {
        label: t('HELP'),
        link: currentLangPath + AvailableRoutes.help,
      },
      links: [
        {
          label: t('DOWNLOAD CENTER'),
          link: currentLangPath + AvailableRoutes.downloadCenter,
        },
        {
          label: t('FAQ'),
          link: currentLangPath + AvailableRoutes.faq,
        },
        {
          label: t('RATE US'),
          link: currentLangPath + AvailableRoutes.rateUs,
        },
        {
          label: t('CONTACT'),
          link: currentLangPath + AvailableRoutes.contact,
        },
      ],
    },
  ]

  const createNavLinkElements = (mode) => {
    return [
      <NavLink
        key='PageSwitchTermine'
        to={currentLangPath + AvailableRoutes.appointment}
        className={navStyles[mode].className}
        activeClassName={navStyles[mode].addActiveClassName}
      >
        {t('APPOINTMENT')}
      </NavLink>,
      <NavLink
        key='PageSwitchHilfe'
        to={currentLangPath + AvailableRoutes.help}
        className={navStyles[mode].className}
        activeClassName={navStyles[mode].addActiveClassName}
      >
        {t('HELP')}
      </NavLink>,
    ]
  }

  return (
    <Container xs sm md lg>
      <Row align='center' justify='between' nogutter>
        <Col>
          <RouteLink
            navLinkElement={
              <NavLink to={'/'}>
                <ImgStyled
                  src={pyrexxLogo}
                  alt='pyrexx-logo'
                  className='py-3'
                />
              </NavLink>
            }
          />
        </Col>

        <Visible md lg xl>
          <Col style={{ alignSelf: 'flex-end' }}>
            <Row justify='end' wrap='nowrap'>
              <Col xs='content'>
                <PageSwitcher
                  navLinkElements={createNavLinkElements('desktop')}
                />
              </Col>

              <Col xs='content'>
                <ButtonFlag
                  language={currentLanguage}
                  isActive
                  handleClick={handleLanguageModalOpen}
                />
              </Col>
            </Row>
          </Col>
        </Visible>

        <Visible xs sm>
          <Col>
            <Row align='center' justify='end' gutterWidth={20}>
              <Col xs='content'>
                <ButtonFlag
                  language={currentLanguage}
                  isActive
                  handleClick={handleLanguageModalOpen}
                />
              </Col>

              <Col xs='content' style={{ display: 'flex' }}>
                <Burger handleClick={() => handleMobileMenu(true)} />
              </Col>
            </Row>
          </Col>
        </Visible>
      </Row>

      <LanguageModalWrapper>
        <LangSwitcherModal
          isOpen={isLanguageModalOpen}
          handleModalClose={handleLanguageModalClose}
          availableLanguages={availableLanguages}
          currentLanguage={currentLanguage}
          changeLanguage={changeLanguage}
        />
      </LanguageModalWrapper>

      <MobileMenu
        isOpen={isMobileMenuOpen}
        handleMobileMenu={handleMobileMenu}
        currentLanguage={currentLanguage}
        lists={lists}
      />
    </Container>
  )
}

export default PspHeader

const NavLink = React.forwardRef(
  ({ activeClassName, activeStyle, ...props }, ref) => {
    return (
      <BaseNavLink
        ref={ref}
        {...props}
        className={({ isActive }) =>
          [props.className, isActive ? activeClassName : null]
            .filter(Boolean)
            .join(' ')
        }
        style={({ isActive }) => ({
          ...props.style,
          ...(isActive ? activeStyle : null),
        })}
      />
    )
  },
)
NavLink.displayName = 'NavLink'
