import styled, { createGlobalStyle } from 'styled-components'
import { Container } from 'react-grid-system'
import { helperStyles, portalTheme } from 'pyrexx-react-library'

export const StyledLayout = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex-grow: 1 !important;

    .main-col {
      padding-top: 15px;
      padding-bottom: 15px;

      > :first-child {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    article {
      height: 100%;
    }
  }
`

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${portalTheme.font.family.opensans};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: ${portalTheme.color.basicColorBlack};
    text-align: left;
    background-color: ${portalTheme.color.basicColorLightGrey1};
  }

  h1, h2, h3, h4, h5 {
    font-family: ${portalTheme.font.family.quicksand};
  }

  p, span, ul, li, label {
    font-family: ${portalTheme.font.family.quicksand};
    font-size: 16px;
  }

  ${helperStyles.allBorderBox}
`
