import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// https://stackoverflow.com/a/76399346
function LangLayout() {
  const { i18n } = useTranslation()
  const { lang } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const path = location.pathname + location.search
  const resolvedLanguage = i18n.resolvedLanguage.substring(0, 2).toLowerCase()

  useEffect(() => {
    if (lang && resolvedLanguage !== lang) {
      if (i18n.options.supportedLngs.includes(lang)) {
        i18n.changeLanguage(lang)
      } else {
        // IN CASE LANGUAGE ISN'T FOUND
        navigate('/' + resolvedLanguage + path, { replace: true })
      }
    }
  }, [lang])

  return <Outlet />
}

export default LangLayout
