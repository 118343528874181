import styled from 'styled-components'
import { helperStyles, portalTheme } from 'pyrexx-react-library'

export const DeleteButtonStyled = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor:pointer;
  color: ${portalTheme.color.errorColor};
  ${helperStyles.buttonRemoveBrowserStylesCss}
  font-size: ${portalTheme.font.size.inputLabel};
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  border: none;
  margin-bottom:1rem;
  svg{
    margin-right:10px;
  }

  @media screen and (max-width: 600px) {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 11px;
    svg{
      font-size:${portalTheme.font.size.headingRegular};
    }
  }
`
