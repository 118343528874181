import { portalTheme } from 'pyrexx-react-library'

// TODO CHECK STATUS, if we are getting the correct strings
const showStatusColor = (color) => {
  switch (color) {
    case 'SUCCESS':
      return portalTheme.color.successColor
    case 'DEACTIVATED':
      return portalTheme.color.disabledColor
    default:
      return portalTheme.color.warningColor
  }
}

export default showStatusColor
