import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  LeftCol,
  RightCol,
  RowStyled,
  TextAreaStyled,
  TextStyled,
} from './RateUs.styles'

const MessageField = (props) => {
  const { suggestions, handleMessageChange } = props

  const { t } = useTranslation()

  return (
    <RowStyled>
      <LeftCol sm={6}>
        <TextStyled>{t('SUGGESTIONS')}</TextStyled>
      </LeftCol>

      <RightCol sm={6}>
        <TextAreaStyled
          placeholder={t('SUGGESTIONS')}
          value={suggestions}
          onChange={handleMessageChange}
        />
      </RightCol>
    </RowStyled>
  )
}
export default MessageField
