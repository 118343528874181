import React from 'react'

import {
  DownloadImg,
  DownloadLink,
  LeftCol,
  RightCol,
  Row,
  Title,
} from './DownloadBox.styles'
import downloadImgSrc from '../../assets/download.svg'

const DownloadBox = ({ file }) => {
  return file.title && file.source_url ? (
    <Row>
      <LeftCol>
        <Title>{file.title}</Title>
      </LeftCol>

      <RightCol>
        <DownloadLink href={file.source_url} target='_blank'>
          <DownloadImg
            src={downloadImgSrc}
            title={file.title + ' download link'}
            alt='click to download file'
          />
        </DownloadLink>
      </RightCol>
    </Row>
  ) : null
}

export default DownloadBox
