import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Slot from './Slot'
import EmptySlot from './EmptySlot'

import formatTimeFrameToString from '../../helper/formatTimeFrameToString'
import { useAppContext } from '../../contexts/AppContext'

const SlotList = (props) => {
  const {
    slots = [],
    handleSlotSelection,
    handleUnavailability,
    currentSlot,
    isUserAvailable,
    emptySlotText,
    validatedEncryptedApartmentId,
  } = props

  const { currentLanguage } = useAppContext()

  return (
    <SlotListStyled>
      {slots?.map((slot, index) => {
        const formattedSlot = formatTimeFrameToString(currentLanguage, slot)

        return (
          <Slot
            key={'slotKey_' + index}
            id={'slotKey_' + index}
            slot={formattedSlot}
            handleSlotSelection={handleSlotSelection}
            currentSlot={currentSlot}
            validatedEncryptedApartmentId={validatedEncryptedApartmentId}
          />
        )
      })}

      <EmptySlot
        handleUnavailability={handleUnavailability}
        currentSlot={currentSlot}
        validatedEncryptedApartmentId={validatedEncryptedApartmentId}
        isUserAvailable={isUserAvailable}
        emptySlotText={emptySlotText}
      />
    </SlotListStyled>
  )
}

const SlotListStyled = styled.div`
  padding: 0;

  button:nth-child(n + 2) {
    margin-top: 1rem;
  }
`

SlotList.propTypes = {
  slots: PropTypes.array,
  handleSlotSelection: PropTypes.func.isRequired,
  handleUnavailability: PropTypes.func.isRequired,
  currentSlot: PropTypes.any,
  isUserAvailable: PropTypes.bool,
  emptySlotText: PropTypes.string,
}

export default SlotList
