import { Col, Row } from 'react-grid-system'
import styled from 'styled-components'
import { portalTheme, SimplePullDown } from 'pyrexx-react-library'

export const StyledTrackingItemPullDown = styled(Row)`
  &:nth-child(n + 2) {
    padding-top: 1rem;
  }
`

export const StyledValue = styled(Col)`
  padding-left: 0.5rem !important;
  font-size: ${portalTheme.font.size.inputLabel};
  font-weight: ${portalTheme.font.weight.regular};
`

export const StyledDateLabel = styled(Col)`
  font-size: ${portalTheme.font.size.bodyRegular};
  font-weight: ${portalTheme.font.weight.bold};
`

export const StyledContainerLabel = styled(Col)`
  font-size: ${portalTheme.font.size.inputLabel};
  font-weight: ${portalTheme.font.weight.bold};
`

export const StyledLabelContainer = styled.div`
    padding: 0.5rem 0;
`

export const StyledValueContainer = styled.div`
  padding-bottom: 1rem;
  font-size: ${portalTheme.font.size.inputLabel};
`

export const StyledTrackingSimplePullDown = styled(SimplePullDown)`
  color: ${portalTheme.color.basicColorBlack};
`
export const HrLine = styled.div`
  border-top: 1px solid rgba(36, 35, 35, 0.75);
`
