import styled from 'styled-components'
import { Container } from 'react-grid-system'

import { portalTheme } from 'pyrexx-react-library'
import { buttonFormList } from '../../styles/PSPHelperStyles.styles'

const ContainerStyled = styled(Container)`
  background-color: ${portalTheme.color.basicColorWhite};
  ${buttonFormList}
`

const TitleStyled = styled.h1`
  text-align: center;
  font-family: ${portalTheme.font.family.quicksand};
  margin-top: 0;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`

const DescriptionStyled = styled.p`
  font-family: ${portalTheme.font.family.quicksand};
  font-size: 14px;

  margin: 0;
`

const SubtitleStyled = styled.h3`
  text-align: center;
  font-weight: 500;
  margin-bottom: 0.5rem;

  font-size: 16px;
`

const EmptyRow = styled.div`
  background: #f7faf5;
  height: 10px;
  margin-left: -15px;
  margin-right: -15px;
`

export {
  ContainerStyled,
  TitleStyled,
  DescriptionStyled,
  SubtitleStyled,
  EmptyRow,
}
