import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import MobileMenuListLink from './MobileMenuListLink'

const MobileMenuListLinks = ({
  links = [],
  currentLanguage,
  handleMobileMenu,
}) => {
  return (
    <List>
      {links
        ? links.map(
            (link) =>
              link.link && (
                <MobileMenuListLink
                  key={link.label}
                  link={link}
                  currentLanguage={currentLanguage}
                  handleMobileMenu={handleMobileMenu}
                />
              ),
          )
        : null}
    </List>
  )
}

const List = styled.ul`
  padding-left: 1em;
`

MobileMenuListLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentLanguage: PropTypes.string.isRequired,
  handleMobileMenu: PropTypes.func.isRequired,
}

export default MobileMenuListLinks
