import styled from 'styled-components'
import {portalTheme} from "pyrexx-react-library";
import {Col, Row} from "react-grid-system";

export const TimelineRow = styled.div`
  position: relative;
  margin-top: 1rem;
`;

export const EventRow = styled(Row)`
  & > div {
    color: lightgray;
    z-index: 10;
  }

  &:first-child > div {
    color: ${portalTheme.color.successColor};
  }

  &:first-child .event_icon_column:before {
    top: 2rem;
  }

  &:last-child .event_icon_column:before {
    height: 2.5rem;
  }
`

export const EventIconCol = styled(Col)`
  &:before {
    content: '';
    border-left: 3px solid lightgray;
    display: inline-block;
    height: 100%;
    position: absolute;
    left: calc(0.5rem + 14px);
    top: 0;
    z-index: -1;
  }
`
