import styled, { css } from 'styled-components'

const isOpenCSS = css`
  left: 0;
  visibility: visible;
`

const MobileMenuContainer = styled.div`
  background-color: white;
  padding: 1em;

  width: 100%;
  height: 100%;

  position: fixed;
  top: 0px;
  left: 100%;
  right: 0px;
  z-index: 100;
  visibility: hidden;

  transition: all 0.4s ease;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  overflow: scroll;

  ${(props) => props.$isOpen && isOpenCSS}
`

export { MobileMenuContainer }
