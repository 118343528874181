import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { ParagraphInfo } from 'pyrexx-react-library'
import { LoadingWrapper } from '../../components/LoadingWrapper'

import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'

const GET_PRIVACY = gql`
  query getPSPPrivacyPolicy($lang: String) {
    getBasicPrivacyPolicy(lang: $lang) {
      coId: co_id
      date
      title
      paragraphs {
        number
        name
        sections {
          name
          number
          text
        }
      }
    }
    getPSPPrivacyPolicy(lang: $lang) {
      coId: co_id
      date
      title
      paragraphs {
        number
        name
        sections {
          name
          number
          text
        }
      }
    }
  }
`

const Privacy = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()

  const { data, loading, error } = useQuery(GET_PRIVACY, {
    variables: { lang: currentLanguage },
  })

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_PRIVACY')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_PRIVACY')}
        />
      </Helmet>

      <LoadingWrapper loading={loading} error={error}>
        <ParagraphInfo content={data} />
      </LoadingWrapper>
    </>
  )
}

export default Privacy
