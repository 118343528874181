import { gql } from '@apollo/client'

export const ADD_NEWSLETTER_SUBSCRIPTION = gql`
  mutation AddNewsletterSubscription(
    $newsletterSubscriptionInfo: NewsletterSubscriptionInfo!
  ) {
    addNewsletterSubscription(
      newsletterSubscriptionInfo: $newsletterSubscriptionInfo
    ) {
      success
    }
  }
`
