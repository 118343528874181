import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-grid-system'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ButtonIcon, portalTheme } from 'pyrexx-react-library'

const MobileMenuHeader = ({ handleMobileMenu }) => {
  const { t } = useTranslation()

  const onModalClose = () => handleMobileMenu(false)

  return (
    <StyledRow>
      <LeftCol xs={8}>
        <h4>{t('PYREXX SERVICE PORTAL')}</h4>
      </LeftCol>

      <RightCol xs={4}>
        <ButtonIcon handleClick={onModalClose} icon='close' size='20px' />
      </RightCol>
    </StyledRow>
  )
}

MobileMenuHeader.propTypes = {
  handleMobileMenu: PropTypes.func.isRequired,
}

const StyledRow = styled(Row)`
  border-bottom: 1px solid;
  border-bottom-color: ${portalTheme.color.basicColorAccentRed};
`

const LeftCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const RightCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export default MobileMenuHeader
