import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'

import { ContainerStyled } from './Home.styles'

const HomeContainer = (props) => {
  const { title, description, form } = props

  return (
    <ContainerStyled component='article' xs sm md>
      <Row justify='center'>
        <Col component='h1' xs='content'>
          {title}
        </Col>
      </Row>

      <Row justify='center'>
        <Col component='p' xs='content'>
          {description}
        </Col>
      </Row>

      <Row justify='center' style={{ marginBottom: '1rem' }}>
        <Col xs={12} sm={10} md={8} lg={6}>
          {form}
        </Col>
      </Row>
    </ContainerStyled>
  )
}

HomeContainer.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  form: PropTypes.element.isRequired,
}

export default HomeContainer
