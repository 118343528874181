import styled from 'styled-components'

import { portalTheme, SimplePullDown } from 'pyrexx-react-library'

const SimplePullDownStyled = styled(SimplePullDown)`
  user-select: unset;

  color: ${portalTheme.color.basicColorBlack};
  font-size: ${portalTheme.font.size.bodyRegular};
  font-weight: ${portalTheme.font.weight.regular};
  text-align: left;

  border: 2px solid;
  border-color: ${portalTheme.color.linkColorSecondary};

  margin-bottom: 0.75rem;
`

const QuestionPulldown = styled(SimplePullDownStyled)`
  border: none;
`

export { SimplePullDownStyled, QuestionPulldown }
