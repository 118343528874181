import React, { useEffect } from 'react'
import { Col, Row } from 'react-grid-system'
import { Outlet, useLocation } from 'react-router-dom'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

import { GlobalStyle, StyledLayout } from './styles'

const HomeLayout = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <StyledLayout fluid>
      <Header />

      <Row component='main'>
        <Col xs={12} className='main-col'>
          <Outlet />
        </Col>
      </Row>

      <Footer />

      <GlobalStyle />
    </StyledLayout>
  )
}

export default HomeLayout
