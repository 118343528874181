import React from 'react'
import PropTypes from 'prop-types'

import MobileMenuHeader from './MobileMenuHeader'
import MobileMenuContent from './MobileMenuContent'

import { MobileMenuContainer } from './MobileMenu.styles'

const MobileMenu = (props) => {
  const { lists, isOpen = false, currentLanguage, handleMobileMenu } = props

  return (
    <MobileMenuContainer $isOpen={isOpen} data-testid='mobile-menu'>
      <MobileMenuHeader handleMobileMenu={handleMobileMenu} />
      <MobileMenuContent
        lists={lists}
        currentLanguage={currentLanguage}
        handleMobileMenu={handleMobileMenu}
      />
    </MobileMenuContainer>
  )
}

export default MobileMenu

MobileMenu.propTypes = {
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }).isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  isOpen: PropTypes.bool,
  handleMobileMenu: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
}
