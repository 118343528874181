import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { StyledInput, ToggleSwitchWithLabel } from 'pyrexx-react-library'
import Navigator from '../../Navigator/Navigator'

import { toggleSwitchWithLabelTheme } from '../../../styles/themes/ToggleSwitchWithLabel.theme'

const TenantInput = (props) => {
  const {
    savedValues: {
      lastName = '',
      firstName = '',
      tel = '',
      tenantChange = false,
      previousTenant = '',
    } = {},
    saveFormInputs,
    prevStep,
    nextStep,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const schema = Yup.object({
    lastName: Yup.string().required(t('IS REQUIRED')),
    firstName: Yup.string().required(t('IS REQUIRED')),
    tel: Yup.string(),
    tenantChange: Yup.bool(),
    previousTenant: Yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      lastName,
      firstName,
      tel,
      tenantChange,
      previousTenant,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      saveFormInputs(values)
      navigate(nextStep)
    },
    validateOnMount: true,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row justify='between'>
        <Col xs={12} md={6}>
          <StyledInput
            type='input'
            name='lastName'
            id='lastName'
            onChange={formik.handleChange}
            value={formik.values.lastName}
            label={t('INHABITANT LAST NAME')}
            feedbackMessage={formik.errors.lastName && formik.touched.lastName}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />

          <StyledInput
            type='input'
            name='firstName'
            id='firstName'
            onChange={formik.handleChange}
            value={formik.values.firstName}
            label={t('INHABITANT FIRST NAME')}
            feedbackMessage={
              formik.errors.firstName && formik.touched.firstName
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />

          <StyledInput
            type='input'
            name='tel'
            id='tel'
            onChange={formik.handleChange}
            value={formik.values.tel}
            label={t('TELEPHONE NUMBER')}
            feedbackMessage={formik.errors.tel}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>

        <Col xs={12} md={6}>
          <ToggleSwitchWithLabel
            theme={toggleSwitchWithLabelTheme}
            label={t('TENANT CHANGE')}
            isChecked={formik.values.tenantChange}
            handleToggleChange={() => {
              formik.setFieldValue('tenantChange', !formik.values.tenantChange)
            }}
          />

          <StyledInput
            disabled={!formik.values.tenantChange}
            type='input'
            name='previousTenant'
            id='previousTenant'
            onChange={formik.handleChange}
            value={formik.values.previousTenant}
            label={t('PREVIOUS TENANT')}
            feedbackMessage={formik.errors.previousTenant}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Navigator prevStep={prevStep} isNextButtonDisabled={!formik.isValid} />
    </form>
  )
}

TenantInput.propTypes = {
  savedValues: PropTypes.object,
  saveFormInputs: PropTypes.func.isRequired,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
}

export default TenantInput
