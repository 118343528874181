import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import MobileMenuRouteLink from './MobileMenuRouteLink'

const MobileMenuListLink = (props) => {
  const { link = {}, handleMobileMenu } = props

  return (
    <ListItem>
      <MobileMenuRouteLink
        linkTo={link.link}
        handleMobileMenu={handleMobileMenu}
        type='link'
      >
        {link.label}
      </MobileMenuRouteLink>
    </ListItem>
  )
}

const ListItem = styled.li`
  margin: 2em 0;

  list-style: none;
`

PropTypes.shape({
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
  currentLanguage: PropTypes.string.isRequired,
  handleMobileMenu: PropTypes.func.isRequired,
})

export default MobileMenuListLink
