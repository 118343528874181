import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SimpleNote, StyledInput } from 'pyrexx-react-library'
import Navigator from '../../Navigator/Navigator'

const LIdInput = (props) => {
  const { savedValues, saveFormInputs, prevStep, nextStep } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const schema = Yup.object({
    appointmentId: Yup.number()
      .nullable(true)
      .transform((v, o) => (o === '' ? null : v))
      .typeError(t('PLEASE ENTER ONLY NUMBERS'))
      .integer(t('PLEASE PROVIDE NUMBER WITHOUT COMMA')),
  })

  const formik = useFormik({
    initialValues: {
      appointmentId: savedValues.appointmentId || '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      saveFormInputs(values)
      navigate(nextStep)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row justify='center'>
        <Col>
          <StyledInput
            type='input'
            name='appointmentId'
            id='appointmentId'
            onChange={formik.handleChange}
            value={formik.values.appointmentId}
            label={t('APPOINTMENT REFERENCE')}
            feedbackMessage={formik.errors.appointmentId}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Row align='center' justify='center'>
        <Col>
          <SimpleNote
            text={t('IF YOU DONT HAVE A REFERENCE NUMBER, CLICK “NEXT”.')}
            textStatus='warning'
          />
        </Col>
      </Row>

      <Navigator prevStep={prevStep} />
    </form>
  )
}

LIdInput.propTypes = {
  savedValues: PropTypes.object,
  saveFormInputs: PropTypes.func.isRequired,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
}

export default LIdInput
