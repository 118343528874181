import styled from 'styled-components'
import { Col } from 'react-grid-system'
import { helperStyles, portalTheme } from 'pyrexx-react-library'

export const StyledLinkFooter = styled(Col)`
  background-color: ${portalTheme.color.basicColorDarkGrey1};
  padding-top: 40px;
  padding-bottom: 40px;

  a {
    ${helperStyles.linkDecorationCSS}
    color: ${portalTheme.color.basicColorWhite} !important;
    font-size: 14px !important;
    font-family: ${portalTheme.font.family.opensans} !important;

    &:hover,
    &:visited,
    &:focus {
      color: #ffffff;
      cursor: pointer;
      border-bottom: none !important;
      outline: 0;
      text-decoration: none;
    }
  }
`
