import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  StyledContainerLabel,
  StyledDateLabel,
  StyledLabelContainer,
  StyledTrackingItemPullDown,
  StyledTrackingSimplePullDown,
  StyledValue,
  StyledValueContainer,
  HrLine,
} from './TrackingItemPullDown.styles'
import { Icon } from 'pyrexx-react-library'

import isValid from '../../helper/isValid'
import showStatusColor from '../../helper/generateStatusColor'
import formatDateLocale from "../../helper/formatDateLocale";

const checkDate = (date) => {
  const parsedDate = new Date(date);
  const regex = /[a-zA-Z]/;
  if(regex.test(date)){
    return false;
  }
  return !isNaN(parsedDate.getTime());
};

const TrackingItemPullDown = (props) => {
  const { eventData, label, date } = props

  const { t } = useTranslation()

  const generateValues = useCallback(() => {
    return isValid(eventData) ? (
      <StyledValueContainer>
        <HrLine></HrLine>
        {eventData.map((event, index) => {
          return (
            event != null && (
              <StyledTrackingItemPullDown
                key={'pulldown' + event.label + index}
                nogutter
              >

                <StyledContainerLabel xs={12} sm={6}>{event.label}</StyledContainerLabel>
                <StyledValue xs={12} sm={6} >
                  {event?.value?.value}
                </StyledValue>
              </StyledTrackingItemPullDown>
            )
          )
        })}
      </StyledValueContainer>
    ) : (
      <StyledValueContainer>
        <div>{t('NO APPOINTMENT INFO AVAILABLE')}</div>
      </StyledValueContainer>
    )
  }, [eventData, t])

  const generateLabel = useCallback(() => {
    return (
      <StyledLabelContainer fluid>
        <Row nogutter>
          <StyledDateLabel>{date}</StyledDateLabel>
        </Row>
        <Row nogutter>
          <StyledDateLabel>{label}</StyledDateLabel>
        </Row>
      </StyledLabelContainer>
    )
  }, [label, date])

  return (
    <StyledTrackingSimplePullDown
      headerLabelElement={generateLabel()}
      values={generateValues()}
    />
  )
}

TrackingItemPullDown.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.string,
  eventData: PropTypes.arrayOf(PropTypes.any),
}

export default TrackingItemPullDown
