import React from 'react'

import ConfirmationResponse from "../../../../components/Confirmation/ConfirmationResponse";
import SuccessResponse from "../../../../components/Confirmation/SuccessResponse";

const Done = (props) => {
  const {
    prevStep,
    nextStep,
    saveFormInputs,
    savedValues,
  } = props

  return (
    <>
      {
        (savedValues.timeframeFrom === null && savedValues.timeframeTo === null)
          ? (<SuccessResponse/>)
          : (<ConfirmationResponse savedValues={savedValues}/>)
      }

    </>
  )
}

export default Done
