import React from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import BackButton from '../../components/BackButton/BackButton'

import {
  ContainerStyled,
  DescriptionStyled,
  EmptyRow,
  SubtitleStyled,
  TitleStyled,
} from './QRScreen.styles'

type QRScreenLayoutProps = {
  company: string
  service: string
  companyShort?: string
  prevStep?: string | number
  showBackArrow: boolean
  children: React.ReactNode
}

const QRScreenLayout: React.FC<QRScreenLayoutProps> = (props) => {
  const {
    company,
    service,
    companyShort = '',
    prevStep = '/',
    showBackArrow = false,
    children,
  } = props

  const { t } = useTranslation()

  return (
    <ContainerStyled component='article' xs sm md>
      <Row justify='center' align='center' style={{ marginBottom: '1rem' }}>
        <Col xs={6} sm={4} lg={3}>
          <DescriptionStyled>{t('YOUR PROPERTY MANAGEMENT')}</DescriptionStyled>
        </Col>
        <Col xs={6} sm={4} lg={3}>
          {companyShort ? companyShort : company}
        </Col>
      </Row>

      <EmptyRow />

      <Row justify='between' align='center' wrap='nowrap' nogutter>
        <Col component='nav' xs={1}>
          {showBackArrow && <BackButton prevStep={prevStep} />}
        </Col>

        <Col>
          <SubtitleStyled>{t('APPOINTMENT FOR:')}</SubtitleStyled>
          <TitleStyled>{service}</TitleStyled>
        </Col>

        <Col xs={1} />
      </Row>

      <Row justify='center' align='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          {children}
        </Col>
      </Row>
    </ContainerStyled>
  )
}

export default QRScreenLayout
