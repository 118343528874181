import styled, { css } from 'styled-components'
import { helperStyles, portalTheme } from 'pyrexx-react-library'

export const buttonFormList = css`
  form {
    margin-top: 1rem;

    button {
      text-align: center;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &.backButton,
      &[type='submit'] {
        margin-top: 2rem;
      }
    }
  }
`

export const StyledDivForLinkTranslation = styled.div`
  a {
    ${helperStyles.linkDecorationCSS}
    color: ${portalTheme.color.linkColorPrimary} !important;
    font-size: 14px !important;
    font-family: ${portalTheme.font.family.opensans} !important;

    &:hover,
    &:visited,
    &:focus {
      color: ${portalTheme.color.linkColorPrimaryHover};
    }
  }
`
