import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import {
  ButtonPrimary,
  ButtonSecondary,
  SimpleNote,
} from 'pyrexx-react-library'
import AppointmentCard from './AppointmentCard'

import { AvailableRoutes } from '../../configs/RouteConfig'
import generateEventUrl from '../../helper/generateEventUrl'
import isObjectValid from '../../helper/isObjectValid'
import formatTimeFrameToString from '../../helper/formatTimeFrameToString'
import { useAppContext } from '../../contexts/AppContext'

const AppointmentResult = (props) => {
  const { timeframe = {}, location = {} } = props

  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()
  const navigate = useNavigate()

  const formattedTimeFrameData = formatTimeFrameToString(
    currentLanguage,
    timeframe,
  )

  const eventData = {
    title: t('YOUR PYREXX APPOINTMENT'),
    ...timeframe,
    ...location,
  }

  const handleAddToCalendar = () => {
    window.open(generateEventUrl(eventData), '_blank')
  }

  const handleCreateAppointment = () =>
    navigate('../' + AvailableRoutes.appointment)

  return (
    <div>
      <AppointmentCard type='location' data={location} />
      <AppointmentCard type='calendar' data={formattedTimeFrameData} />

      {isObjectValid(timeframe) && (
        <>
          <SimpleNote
            text={t(
              'PLEASE MAKE SURE THAT YOUR RESIDENCE IS ACCESSIBLE AT THE GIVEN TIME.',
            )}
          />

          <ButtonSelector>
            <ButtonPrimary onClick={handleAddToCalendar}>
              {t('ADD TO CALENDAR')}
            </ButtonPrimary>
            <ButtonSecondary onClick={handleCreateAppointment}>
              {t('SCHEDULE INDIVIDUAL APPOINTMENT')}
            </ButtonSecondary>
          </ButtonSelector>
        </>
      )}
    </div>
  )
}

const ButtonSelector = styled.div`
  display: flex;

  justify-content: space-around;
  flex-flow: wrap row-reverse;

  margin-top: 1.5rem;

  button {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }
`

export default AppointmentResult
