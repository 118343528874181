import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'
import TenantInput from '../../../../components/Steps/Inputs/TenantInput'

const Start = (props) => {
  const { prevStep, nextStep, saveFormInputs, savedValues } = props

  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
    // TODO improve this
    saveFormInputs({
      serviceCategory: 'SMOKEDETECTOR',
      appointmentType: 'TS',
      appointmentId: '',
    })
  }, [])

  const getQueryParams = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get('root');
  };

  return (
    <StepContainer
      title={t('APPOINTMENT PLANNING')}
      description={t('PROBLEM WITH A SMOKE DETECTOR')}
      subtitle={t('INFORMATION ABOUT THE RESIDENCE') + ' 1/2'}
      form={
        <TenantInput
          prevStep={getQueryParams() === 'incident' ? '/' : prevStep}
          nextStep={nextStep}
          savedValues={savedValues}
          saveFormInputs={saveFormInputs}
        />
      }
    />
  )
}

Start.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default Start
