import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { StyledInput } from 'pyrexx-react-library'
import Navigator from '../../Navigator/Navigator'

const TrackingIdInput = (props) => {
  const { savedValues = {}, saveFormInputs, nextStep } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const schema = Yup.object({
    trackingId: Yup.string().required(t('TRACKING ID IS REQUIRED')),
  })

  const formik = useFormik({
    initialValues: {
      trackingId: savedValues.trackingId || '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      saveFormInputs(values)
      navigate(nextStep)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row justify='center'>
        <Col>
          <StyledInput
            type='input'
            name='trackingId'
            id='trackingId'
            onChange={formik.handleChange}
            value={formik.values.trackingId}
            label={t('TRACKING ID')}
            feedbackMessage={formik.errors.trackingId}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Navigator isNextButtonDisabled={!formik.values.trackingId} />
    </form>
  )
}

TrackingIdInput.propTypes = {
  savedValues: PropTypes.object,
  saveFormInputs: PropTypes.func.isRequired,
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
}

export default TrackingIdInput
