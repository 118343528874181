import { ButtonSecondary, portalTheme, SimpleLabel } from 'pyrexx-react-library'
import styled from 'styled-components'

export const StyledSimpleNote = styled(SimpleLabel)`
  font-size: ${portalTheme.font.size.headingSmall};
  font-weight: ${portalTheme.font.weight.bold};
  color: ${portalTheme.color.warningColor};
`

export const StyledButton = styled(ButtonSecondary)`
  color: ${portalTheme.color.basicColorBlack};
  border-color: ${portalTheme.color.basicColorBlack};
  &:hover,
  active {
    border-color: ${portalTheme.color.secondaryButtonBorderActive};
    color: ${portalTheme.color.secondaryButtonBorderActive};
  }
`
