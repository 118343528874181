import React from 'react'
import PropTypes from 'prop-types'

import { StyledContactPullDown } from './ContactPullDown.styles'

const ContactPullDown = (props) => {
  const { label, element } = props

  return <StyledContactPullDown headerLabelElement={label} values={element} />
}

ContactPullDown.propTypes = {
  label: PropTypes.string.isRequired,
  element: PropTypes.any.isRequired,
}

export default ContactPullDown
