import styled from 'styled-components'
import { Col } from 'react-grid-system'
import { helperStyles, portalTheme } from 'pyrexx-react-library'

export const StyledSocialFooter = styled(Col)`
  background-color: ${portalTheme.color.basicColorDarkGrey2};
  padding-top: 1rem;
  padding-bottom: 1rem;

  ul {
    ${helperStyles.unstyledList}
    li {
      line-height: 0;
    }
  }

  a {
    ${helperStyles.linkDecorationCSS}
    color: ${portalTheme.color.basicColorWhite} !important;
    display: inline-block;
  }
`
