import React from 'react'
import { Col } from 'react-grid-system'

import PspHeader from './PspHeader/PspHeader'

import { PspHeaderRow } from './PspHeader/PspHeader.styles'

const Header = () => {
  return (
    <PspHeaderRow component='nav'>
      <Col>
        <PspHeader />
      </Col>
    </PspHeaderRow>
  )
}

export default Header
