import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'

import BackButton from '../../../BackButton/BackButton'

import {
  ContainerStyled,
  DescriptionStyled,
  SubtitleStyled,
  TitleStyled,
} from './StepContainer.styles'

const StepContainer = (props) => {
  const {
    title,
    description = '',
    subtitle,
    form,
    prevStep = '',
    showBackArrow = false,
  } = props

  return (
    <ContainerStyled component='article' xs sm md>
      <Row justify='between' align='center' wrap='nowrap' nogutter>
        <Col component='nav' xs={1}>
          {showBackArrow && <BackButton prevStep={prevStep} />}
        </Col>

        <Col>
          <TitleStyled>{title}</TitleStyled>
        </Col>

        <Col xs={1} />
      </Row>

      <Row justify='center' align='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          <DescriptionStyled>{description}</DescriptionStyled>
        </Col>
      </Row>

      {subtitle && (
        <Row justify='center' align='center'>
          <Col xs={12} sm={10} md={8} lg={6}>
            <SubtitleStyled>{subtitle}</SubtitleStyled>
          </Col>
        </Row>
      )}

      <Row justify='center' align='center' style={{ marginBottom: '1rem' }}>
        <Col xs={12} sm={10} md={8} lg={6}>
          {form}
        </Col>
      </Row>
    </ContainerStyled>
  )
}

StepContainer.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.any,
  subtitle: PropTypes.any,
  form: PropTypes.element.isRequired,
  prevStep: PropTypes.string,
  showBackArrow: PropTypes.bool,
}

export default StepContainer
