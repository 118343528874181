import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { ButtonPrimary, SimpleNote, StyledInput } from 'pyrexx-react-library'

const ContactFormDesktopInputs = (props) => {
  const { formik, submitStatus } = props

  const { t } = useTranslation()

  return (
    <>
      <Row justify='center' style={{ paddingBottom: '1rem' }}>
        <Col xs={12} md={12}>
          <SimpleNote
            noteStatus='warning'
            text={t(
              'PLEASE NOTE THAT SERVICE REQUESTS / ERROR REPORTS OR APPOINTMENT REQUESTS CANNOT BE PROCESSED HERE, USE THE RESPECTIVE MENU FOR THE SERVICE',
            )}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='input'
            name='appointmentId'
            id='appointmentId'
            onChange={formik.handleChange}
            value={formik.values.appointmentId}
            label={t('APPOINTMENT ID')}
            feedbackMessage={
              formik.touched.appointmentId && formik.errors.appointmentId
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='input'
            name='contactName'
            id='contactName'
            onChange={formik.handleChange}
            value={formik.values.contactName}
            label={t('NAME, FIRST NAME')}
            feedbackMessage={
              formik.touched.contactName && formik.errors.contactName
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='input'
            name='address'
            id='address'
            onChange={formik.handleChange}
            value={formik.values.address}
            label={t('ADDRESS')}
            feedbackMessage={formik.touched.address && formik.errors.address}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={6}>
          <StyledInput
            type='input'
            name='apartmentNr'
            id='apartmentNr'
            onChange={formik.handleChange}
            value={formik.values.apartmentNr}
            label={t('RESIDENCE NUMBER')}
            feedbackMessage={
              formik.touched.apartmentNr && formik.errors.apartmentNr
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
        <Col xs={6}>
          <StyledInput
            type='input'
            name='floor'
            id='floor'
            onChange={formik.handleChange}
            value={formik.values.floor}
            label={t('FLOOR')}
            feedbackMessage={formik.touched.floor && formik.errors.floor}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={6}>
          <StyledInput
            type='input'
            name='zip'
            id='zip'
            onChange={formik.handleChange}
            value={formik.values.zip}
            label={t('ZIP')}
            feedbackMessage={formik.touched.zip && formik.errors.zip}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
        <Col xs={6}>
          <StyledInput
            type='input'
            name='town'
            id='town'
            onChange={formik.handleChange}
            value={formik.values.town}
            label={t('TOWN')}
            feedbackMessage={formik.touched.town && formik.errors.town}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='input'
            name='contactEmail'
            id='contactEmail'
            onChange={formik.handleChange}
            value={formik.values.contactEmail}
            label={t('EMAIL')}
            feedbackMessage={
              formik.touched.contactEmail && formik.errors.contactEmail
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='textarea'
            name='question'
            id='question'
            onChange={formik.handleChange}
            value={formik.values.question}
            label={t('YOUR REQUEST')}
            feedbackMessage={formik.touched.question && formik.errors.question}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs='content'>
          {submitStatus.status && (
            <SimpleNote
              noteStatus={submitStatus.status}
              text={submitStatus.message}
            />
          )}
        </Col>
      </Row>

      <Row justify='center' style={{ marginBottom: '1rem' }}>
        <Col xs='content'>
          <ButtonPrimary type='submit' isLoading={submitStatus.loading}>
            {t('SEND REQUEST')}
          </ButtonPrimary>
        </Col>
      </Row>
    </>
  )
}

ContactFormDesktopInputs.propTypes = {
  formik: PropTypes.any.isRequired,
  submitStatus: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    status: PropTypes.any.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
}

export default ContactFormDesktopInputs
