import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// @ts-ignore
import { portalTheme } from 'pyrexx-react-library'

export type Floor = {
  value: string
  label: string
}

type FloorSelectorProps = {
  id?: string
  name?: string
  floors: Floor[]
  selectedFloor: string
  handleSelectChange: React.ChangeEvent
}

const FloorSelector = (props: FloorSelectorProps) => {
  const {
    floors = [],
    selectedFloor = '',
    handleSelectChange,
    id,
    name,
  } = props

  const { t } = useTranslation()

  // const sortedTimeframes = [...floors]
  //   .sort((a, b) => {
  //     if (a?.value > b?.value) {
  //       return 1
  //     }
  //     if (a?.value < b?.value) {
  //       return -1
  //     }
  //     return 0
  //   })
  //   // To filter repeated floor values
  //   .filter(
  //     (tf, idx, self) =>
  //       self.findIndex((item) => item?.value === tf?.value) === idx,
  //   )

  return (
    <Wrapper>
      <TextStyled>{t('PLEASE SELECT YOUR TIMEFRAME') + ': '}</TextStyled>

      <SelectStyled
        id={id}
        name={name}
        value={selectedFloor}
        // @ts-ignore
        onChange={handleSelectChange}
      >
        <option value=''>-</option>

        {floors.map((floor) => (
          <option key={floor?.value} value={floor?.value}>
            {floor?.label}
          </option>
        ))}
      </SelectStyled>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 1.5rem 0;
`

const TextStyled = styled.p`
  margin: 0.5rem;

  text-align: center;
  font-family: ${portalTheme.font.family.quicksand};
`

const SelectStyled = styled.select`
  margin-top: 0 !important;
  width: 100%;

  font-size: 1.5rem;
  text-align-last: center;

  color: ${portalTheme.color.linkColorPrimary};
  font-family: ${portalTheme.font.family.quicksand};
`

export default FloorSelector
