import { Row } from 'react-grid-system'
import styled from 'styled-components'

import { ButtonIcon, portalTheme } from 'pyrexx-react-library'

const ImgStyled = styled.img`
  box-sizing: border-box;

  max-height: 80px;
  height: 70%;
  width: auto;

  padding: 1rem 0;
`

const Burger = styled(ButtonIcon).attrs((props) => ({
  icon: 'mobile-menu',
  size: '25px',
}))`
  padding-left: 0.75rem;
  margin: 0;
`

const PspHeaderRow = styled(Row)`
  background-color: white;
  border-bottom: 3px solid ${portalTheme.color.basicColorAccentRed};

  /* class-name='sticky-top' */
  /* position: sticky;
  top: 0; */
`

const LanguageModalWrapper = styled.div`
  & [data-simple-modal-content] > div:nth-child(2) {
    justify-content: space-evenly;
  }
`

export { ImgStyled, PspHeaderRow, Burger, LanguageModalWrapper }
