import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { ButtonPrimary, SimpleNote, StyledInput } from 'pyrexx-react-library'

import { ADD_NEWSLETTER_SUBSCRIPTION } from '../../mutations/AddNewsletterSubscription'

const NewsletterForm = ({ currentLanguage = 'de' }) => {
  const { t } = useTranslation()

  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    status: false,
    message: '',
  })

  const [addNewsletterSubscription] = useMutation(ADD_NEWSLETTER_SUBSCRIPTION)

  const schema = Yup.object({
    newsletterSubscriptionEmail: Yup.string()
      .email()
      .required(t('WE NEED A CONTACT EMAIL')),
  })

  const formik = useFormik({
    initialValues: {
      lang: currentLanguage,
      newsletterSubscriptionEmail: '',
    },
    validationSchema: schema,
    onSubmit: (values, formik) => {
      setSubmitStatus({
        loading: true,
        status: false,
        message: '',
      })

      addNewsletterSubscription({
        variables: { newsletterSubscriptionInfo: schema.cast(values) },
        onCompleted: (data) => {
          if (data?.addNewsletterSubscription?.success) {
            setSubmitStatus({
              loading: false,
              status: 'success',
              message: t('NEWSLETTER REGISTRATION SUCCESSFUL'),
            })
            formik.resetForm()
          } else {
            setSubmitStatus({
              loading: false,
              status: 'error',
              message: t(
                'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
              ),
            })
          }
        },
        onError: () => {
          setSubmitStatus({
            loading: false,
            status: 'error',
            message: t(
              'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
            ),
          })
        },
      })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='input'
            name='newsletterSubscriptionEmail'
            id='newsletterSubscriptionEmail'
            onChange={formik.handleChange}
            value={formik.values.newsletterSubscriptionEmail}
            label={t('EMAIL')}
            feedbackMessage={
              formik.touched.newsletterSubscriptionEmail &&
              formik.errors.newsletterSubscriptionEmail
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs='content'>
          {submitStatus.status && (
            <SimpleNote
              noteStatus={submitStatus.status}
              text={submitStatus.message}
            />
          )}
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs='content'>
          <ButtonPrimary type='submit' isLoading={submitStatus.loading}>
            {t('SUBSCRIBE')}
          </ButtonPrimary>
        </Col>
      </Row>
    </form>
  )
}

export default NewsletterForm
