import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'
import TrackingIdInput from '../../../../components/Steps/Inputs/TrackingIdInput'

const InsertTrackingId = (props) => {
  const { prevStep, nextStep, saveFormInputs, savedValues } = props

  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <StepContainer
      prevStep={prevStep}
      showBackArrow
      title={t('APPOINTMENT TRACKING')}
      description={t('YOU CAN TRACK YOUR ORDER HERE')}
      subtitle=''
      form={
        <TrackingIdInput
          savedValues={savedValues}
          saveFormInputs={saveFormInputs}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      }
    />
  )
}

InsertTrackingId.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default InsertTrackingId
