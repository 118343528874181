import formatDateLocale from './formatDateLocale'

const formatTimeFrameToString = (currentLanguage = 'de', timeframe = {}) => {
  const date = formatDateLocale(currentLanguage, timeframe.from, 'P')
  const day = formatDateLocale(currentLanguage, timeframe.from, 'iii')
  const from = formatDateLocale(currentLanguage, timeframe.from, 'p')
  const to = formatDateLocale(currentLanguage, timeframe.to, 'p')

  return { date, day, from, to }
}

export default formatTimeFrameToString
