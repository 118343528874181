import React from 'react'

import Stars from './Stars'

import { RowStyled, LeftCol, RightCol, TextStyled } from './RateUs.styles'

const StarsField = (props) => {
  const { question, rateValue, handleStarSelection } = props

  return (
    <RowStyled>
      <LeftCol sm={6}>
        <TextStyled>{question.name}</TextStyled>
      </LeftCol>

      <RightCol sm={6}>
        <Stars
          questionId={question.id}
          rateValue={rateValue}
          handleStarSelection={handleStarSelection}
        />
      </RightCol>
    </RowStyled>
  )
}

export default StarsField
