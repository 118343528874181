import React from 'react'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ButtonPrimary, ButtonSelector } from 'pyrexx-react-library'

import { AvailableRoutes } from '../../configs/RouteConfig'

const HelpInput = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const schema = Yup.object({
    nextRoute: Yup.string().required(t('CHOOSE ONE CATEGORY')),
  })

  const formik = useFormik({
    initialValues: {
      nextRoute: undefined,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      navigate('../' + values.nextRoute)
    },
  })

  const handleClick = (route) => {
    formik.setFieldValue('nextRoute', route.route)
  }

  const helpRoutes = [
    {
      route: AvailableRoutes.downloadCenter,
      label: t('DOWNLOAD CENTER'),
    },
    {
      route: AvailableRoutes.faq,
      label: t('FAQ'),
    },
    {
      route: AvailableRoutes.rateUs,
      label: t('RATE US'),
    },
    {
      route: AvailableRoutes.contact,
      label: t('CONTACT'),
    },
  ]

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row justify='center'>
        <Col>
          {helpRoutes.map((route) => (
            <ButtonSelector
              key={route.route}
              type='button'
              isActive={formik.values.nextRoute === route.route}
              onClick={() => handleClick(route)}
            >
              {route.label}
            </ButtonSelector>
          ))}
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs='content'>
          <ButtonPrimary type='submit' disabled={!formik.values.nextRoute}>
            {t('NEXT')}
          </ButtonPrimary>
        </Col>
      </Row>
    </form>
  )
}

export default HelpInput
