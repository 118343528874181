import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AppointmentSelection from '../../../../components/Steps/Inputs/AppointmentSelection'
import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'

import { AvailableRoutes } from '../../../../configs/RouteConfig'

const Start = (props) => {
  const { prevStep, nextStep, saveFormInputs } = props

  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <StepContainer
      prevStep={prevStep}
      showBackArrow
      title={t('APPOINTMENT MISSED')}
      description={
        <>
          {t('DID YOU MISS YOUR APPOINTMENT?')}
          <br />
          {t('FOR WHICH SERVICE?')}
        </>
      }
      subtitle=''
      form={
        <AppointmentSelection
          nextStep={nextStep}
          saveFormInputs={saveFormInputs}
        />
      }
    />
  )
}

Start.propTypes = {
  nextStep: PropTypes.string,
  saveFormInputs: PropTypes.func.isRequired,
}

export default Start
