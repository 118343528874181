import React from 'react'
import styled, { css } from 'styled-components'

import { ButtonIcon, portalTheme } from 'pyrexx-react-library'

const StarHover = css`
  color: ${portalTheme.color.basicColorAccentRed};
  opacity: 0.6;
`

const StarActive = css`
  color: ${portalTheme.color.basicColorAccentRed};
  opacity: 1;
`

const StarStyled = styled(ButtonIcon)`
  color: ${portalTheme.color.basicColorLightGrey2};
  padding: 0 0.25rem;

  transition: all 0.3s ease;

  ${(props) => (props.isActive && StarActive) || (props.isHover && StarHover)};
`

const Star = (props) => {
  return <StarStyled icon='star-active' size='40px' type='button' {...props} />
}

export default Star
