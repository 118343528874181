import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import { Spinner } from 'pyrexx-react-library'
import AppointmentResult from './AppointmentResult'
import ModalPopup from '../ModalPopup/ModalPopup'

import isObjectValid from '../../helper/isObjectValid'

const CHECK_APPOINTMENT = gql`
  query AppointmentResultContainerQuery(
    $validation: AppointmentCheckValidation!
  ) {
    getAppointmentCheck(validation: $validation) {
      timeframe: timeFrame {
        from
        to
      }
      validation {
        success
      }
    }
  }
`

const AppointmentResultContainer = (props) => {
  const { savedValues = {}, prevStep } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const variables = {
    validation: {
      appointmentId: savedValues.appointmentId,
      firstName: savedValues.firstName,
      lastName: savedValues.lastName,
      street: savedValues.street,
      floor: savedValues.floor,
      zip: savedValues.zip,
      town: savedValues.town,
    },
  }

  const location = {
    street: savedValues.street,
    zip: savedValues.zip,
    town: savedValues.town,
  }

  let errorMessage = ''

  const { data, loading, error } = useQuery(CHECK_APPOINTMENT, {
    variables,
    fetchPolicy: 'no-cache',
  })

  const { timeframe = false, validation = {} } = data?.getAppointmentCheck || {}
  const validationSuccess = validation?.success || false

  if (loading) return <Spinner />
  if (error) {
    errorMessage = t(
      'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
    )
  }

  if (
    !errorMessage &&
    !isObjectValid(data?.getAppointmentCheck || !timeframe)
  ) {
    errorMessage = t(
      'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
    )
  }

  if (!errorMessage && !validationSuccess) {
    errorMessage = t(
      'UNFORTUNATELY WE COULD NOT FIND YOUR ADDRESS. PLEASE CHECK YOUR DETAILS AGAIN OR CALL SUPPORT.',
    )
  }

  if (errorMessage) {
    return (
      <ModalPopup
        isOpen
        showButtonClose={false}
        noteText={<p>{errorMessage}</p>}
        handleModalClose={() => {}}
        onClick={() => navigate(prevStep)}
        buttonText={t('BACK')}
      />
    )
  } else {
    return <AppointmentResult timeframe={timeframe} location={location} />
  }
}

export default AppointmentResultContainer
