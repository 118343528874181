import { AvailableRoutes } from '../configs/RouteConfig'

const generatePrevNextRoutes = ({ steps, routeIndexStep }) => {
  const prev = steps?.find((step) => step.index === routeIndexStep - 1)?.slug
  const next = steps?.find((step) => step.index === routeIndexStep + 1)?.slug

  const prevStep = prev ? `../${prev}` : '../../' + AvailableRoutes.appointment
  const nextStep = next ? `../${next}` : '../../' + AvailableRoutes.appointment

  return [prevStep, nextStep]
}

export default generatePrevNextRoutes
