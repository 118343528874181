import React from 'react'

import { ButtonSelector } from 'pyrexx-react-library'

const EmptySlot = (props) => {
  const {
    handleUnavailability,
    currentSlot,
    isUserAvailable,
    emptySlotText,
    validatedEncryptedApartmentId,
  } = props

  return (
    <ButtonSelector
      type='button'
      isActive={!isUserAvailable && currentSlot == null}
      onClick={() => handleUnavailability(validatedEncryptedApartmentId)}
    >
      <span>{emptySlotText}</span>
    </ButtonSelector>
  )
}

export default EmptySlot
