import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'

import { ImprintInfo } from 'pyrexx-react-library'
import { LoadingWrapper } from '../../components/LoadingWrapper'

import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../contexts/AppContext'

const GET_IMPRINT = gql`
  query getImprintQuery($lang: String) {
    getImprint(lang: $lang) {
      coId: co_id
      date
      title
      paragraphs {
        number
        name
        sections {
          name
          number
          text
        }
      }
    }
  }
`

const Imprint = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()

  const { data, loading, error } = useQuery(GET_IMPRINT, {
    variables: { lang: currentLanguage },
  })

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_IMPRINT')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_IMPRINT')}
        />
      </Helmet>

      <LoadingWrapper loading={loading} error={error}>
        <ImprintInfo content={data} />
      </LoadingWrapper>
    </>
  )
}

export default Imprint
