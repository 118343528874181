import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

// @ts-ignore
import { ButtonPrimary } from 'pyrexx-react-library'

const EmailConfirmation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div>
      <h3>{t('ALMOST THERE, CONFIRM YOUR NOTIFICATION REQUEST')}</h3>
      <p>
        {t(
          'YOU WILL RECEIVE THE ACTIVATION EMAIL IN YOUR MAILBOX WITHIN 24 HOURS. BE SURE TO CHECK YOUR SPAM FOLDER.',
        )}
      </p>
      <CenterRow>
        <ButtonPrimary onClick={() => navigate('/')}>
          {t('RETURN HOME')}
        </ButtonPrimary>
      </CenterRow>
    </div>
  )
}

const CenterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    margin-top: 1rem;
  }
`

export default EmailConfirmation
