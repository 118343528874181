import React from 'react'
import ReactGA from 'react-ga'

const init = () => {
  const isGAEnabled = process.env.NODE_ENV === 'production'

  if (isGAEnabled) {
    ReactGA.initialize('UA-106971845-9')
  }

  return isGAEnabled
}

const GoogleModalView = (modalName) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.modalview(modalName)
  }
}

const logPageChange = (pathname, search = '') => {
  const page = pathname + search
  const { location } = window

  ReactGA.set({
    page,
    location: `${location.origin}${page}`,
    anonymizeIp: true,
  })

  ReactGA.pageview(page)
}

export default {
  init,
  GoogleModalView,
  logPageChange,
}
