import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import DownloadList from './DownloadList'
import { Spinner } from 'pyrexx-react-library'

const LANG_MAP = {
  en: 'downloads-en',
  de: 'downloads-de',
}

const useDownloads = () => {
  return useQuery({
    queryKey: ['downloads'],
    queryFn: () =>
      fetch(process.env.REACT_APP_WP_MEDIA_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then((res) => res.json()),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

const DownloadListContainer = (props) => {
  const { currentLanguage } = props

  const { t } = useTranslation()

  const { data, isLoading, isError } = useDownloads()

  const rows = data
    ?.map((r) => ({
      id: r.id,
      title: r.title.rendered,
      slug: r.slug,
      source_url: r.source_url,
      mime_type: r.mime_type,
      pyrexx_media_category: r.pyrexx_media_category,
    }))
    .filter((r) => r.pyrexx_media_category === LANG_MAP[currentLanguage])

  if (isLoading) return <Spinner />
  if (isError)
    return (
      <p>
        {t(
          'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
        )}
      </p>
    )

  return <DownloadList files={rows} />
}

DownloadListContainer.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
}

export default DownloadListContainer
