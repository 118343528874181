import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import SupportContainer from '../../components/Steps/Containers/SupportContainer/SupportContainer'
import DownloadListContainer from '../../components/Download/DownloadListContainer'

import { AvailableRoutes } from '../../configs/RouteConfig'
import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'

const DownloadCenter = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_DOWNLOAD_CENTER')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_DOWNLOAD_CENTER')}
        />
      </Helmet>

      <SupportContainer
        prevStep={'../' + AvailableRoutes.help}
        showBackArrow
        title={t('DOWNLOAD CENTER')}
        // description={t('PLACEHOLDER TEXT')}
        form={<DownloadListContainer currentLanguage={currentLanguage} />}
      />
    </>
  )
}

export default DownloadCenter
