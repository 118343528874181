import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AppContext } from './AppContext'

export const AppProvider = ({ children }) => {
  const { i18n } = useTranslation()

  // Chat
  const [chat, setChat] = useState(null)

  // Language
  const currentLanguage =
    i18n.resolvedLanguage?.substring(0, 2)?.toLowerCase() || 'de'
  const currentLangPath = '/' + currentLanguage + '/'

  return (
    <AppContext.Provider
      value={{
        currentLanguage,
        currentLangPath,
        chat,
        setChat,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
