import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BackButton from '../../../BackButton/BackButton'
import { RouteLink } from 'pyrexx-react-library'

import {
  ContainerStyled,
  DescriptionStyled,
  SubtitleStyled,
  TitleStyled,
} from './SupportContainer.styles'

const SupportContainer = (props) => {
  const {
    title,
    description = '',
    subtitle,
    form,
    prevStep = '',
    showBackArrow = false,
  } = props

  const { t } = useTranslation()

  return (
    <ContainerStyled component='article' xs sm md>
      <Row justify='between' align='center' wrap='nowrap' nogutter>
        <Col component='nav' xs={1}>
          {showBackArrow && <BackButton prevStep={prevStep} />}
        </Col>

        <Col>
          <TitleStyled>{title}</TitleStyled>
        </Col>

        <Col xs={1} />
      </Row>

      <Row justify='center' align='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          <DescriptionStyled>{description}</DescriptionStyled>
        </Col>
      </Row>

      {subtitle && (
        <Row justify='center' align='center'>
          <Col xs={12} sm={10} md={8} lg={6}>
            <SubtitleStyled>{subtitle}</SubtitleStyled>
          </Col>
        </Row>
      )}

      <Row justify='center' align='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          {form}
        </Col>
      </Row>

      <Row justify='center' style={{ margin: '2rem 0 1rem 0' }}>
        <RouteLink
          navLinkElement={
            <Link to={prevStep} className='route-link__simple-link'>
              {t('DO YOU HAVE MORE QUESTIONS?')}
            </Link>
          }
        />
      </Row>
    </ContainerStyled>
  )
}

SupportContainer.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.any,
  subtitle: PropTypes.any,
  form: PropTypes.element.isRequired,
  prevStep: PropTypes.string,
  showBackArrow: PropTypes.bool,
}

export default SupportContainer
