import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Col, Row } from 'react-grid-system'

import { SimpleNote } from 'pyrexx-react-library'
import StarsField from './StarsField'
import MessageField from './MessageField'
import SaveButton from '../SaveButton/SaveButton'

import isValid from '../../helper/isValid'
import { ADD_RATES } from '../../mutations/AddRatesMutation'

const initialRates = []
const initialSuggestions = ''

const RateUsInput = (props) => {
  const { questions = [] } = props

  const { t } = useTranslation()

  const [rates, setRates] = useState(initialRates)
  const [suggestions, setSuggestions] = useState(initialSuggestions)

  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    status: false,
    message: '',
  })

  const [addRates] = useMutation(ADD_RATES)

  const handleStarSelection = (rate) => {
    setRates((prevState) => {
      const filteredRepeatedRates = prevState.filter((q) => q.id !== rate.id)
      return [...filteredRepeatedRates, rate]
    })
  }

  const handleMessageChange = (e) => {
    setSuggestions(e.target.value)
  }

  const resetForm = () => {
    setRates(initialRates)
    setSuggestions(initialSuggestions)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitStatus({
      loading: false,
      status: false,
      message: '',
    })

    if (rates.length > 0 || suggestions.length > 0) {
      setSubmitStatus((prevState) => ({ ...prevState, loading: true }))

      const variables = { rates, suggestions }

      const onCompleted = (data) => {
        if (data.addRates.success) {
          setSubmitStatus({
            loading: false,
            status: 'success',
            message: t('RATING WAS SUCCESSFULLY SENT'),
          })
          resetForm()
        } else {
          setSubmitStatus({
            loading: false,
            status: 'error',
            message: t(
              'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
            ),
          })
        }
      }
      const onError = () => {
        setSubmitStatus({
          loading: false,
          status: 'error',
          message: t(
            'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
          ),
        })
      }

      addRates({ variables, onCompleted, onError })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {isValid(questions) ? (
        questions.map((question) => {
          const rateValue = rates.find((q) => q.id === question.id)?.value

          return (
            <StarsField
              key={question.id}
              question={question}
              rateValue={rateValue}
              handleStarSelection={handleStarSelection}
            />
          )
        })
      ) : (
        <p>{t('NO AVAILABLE QUESTIONS')}</p>
      )}

      <MessageField
        suggestions={suggestions}
        handleMessageChange={handleMessageChange}
      />

      <SaveButton isLoading={submitStatus.loading} />

      <Row justify='center' style={{ marginBottom: '1rem' }}>
        <Col xs='content'>
          {submitStatus.status && (
            <SimpleNote
              noteStatus={submitStatus.status}
              text={submitStatus.message}
            />
          )}
        </Col>
      </Row>
    </form>
  )
}

export default RateUsInput
