import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import StepContainer from '../../../../components/Steps/Containers/StepContainer/StepContainer'
import ContactInput from '../../../../components/Steps/Inputs/ContactInput'

import { AvailableRoutes } from '../../../../configs/RouteConfig'

const Step5 = (props) => {
  const {
    prevStep,
    nextStep,
    saveFormInputs,
    savedValues,
    appointmentTypeDescriptions,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!savedValues?.serviceCategory) {
      navigate('../../' + AvailableRoutes.appointmentMissed.root)
    }
  }, [savedValues?.serviceCategory])

  return (
    <StepContainer
      title={t('APPOINTMENT MISSED')}
      description={t('YOU MISSED YOUR APPOINTMENT FOR {SERVICE}?', {
        SERVICE: appointmentTypeDescriptions[savedValues.serviceCategory],
      })}
      subtitle={t('CONTACT INFO')}
      form={
        <ContactInput
          savedValues={savedValues}
          saveFormInputs={saveFormInputs}
          prevStep={prevStep}
          nextStep={nextStep}
          maxSteps={(AvailableRoutes.appointmentMissed.steps.length - 2)}
          currentStep={5}
        />
      }
    />
  )
}

Step5.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  saveFormInputs: PropTypes.func.isRequired,
}

export default Step5
