import React from 'react'
import { Col, Container, Row } from 'react-grid-system'

import { Icon, SimpleLink } from 'pyrexx-react-library'

import { AvailableLinks } from '../../../configs/LinkConfig'

const socialLinks = [
  {
    iconName: 'social-linked',
    link: AvailableLinks.linkedin,
  },
  {
    iconName: 'social-youtube',
    link: AvailableLinks.youtube,
  },
]

const SocialFooter = () => {
  return (
    <Container xs sm md>
      <Row component='ul' align='center' justify='center'>
        {socialLinks.map((linkObject) => (
          <Col key={linkObject.iconName} component='li' xs='content'>
            <SimpleLink
              label={
                <Icon
                  icon={linkObject.iconName}
                  src={linkObject.link}
                  size='50px'
                />
              }
              url={AvailableLinks.facebook}
              target='_blank'
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}
export default SocialFooter
