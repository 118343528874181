const applicationServerKey = process.env.REACT_APP_APPLICATION_SERVER_KEY || ''

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function checkNotificationPermission() {
  return new Promise<void>((resolve, reject) => {
    if (Notification.permission === 'denied') {
      return reject(new Error('Push messages are blocked.'))
    }

    if (Notification.permission === 'granted') {
      return resolve()
    }

    if (Notification.permission === 'default') {
      return Notification.requestPermission().then((result) => {
        if (result !== 'granted') {
          reject(new Error('Bad permission result'))
        } else {
          resolve()
        }
      })
    }

    return reject(new Error('Unknown permission'))
  })
}

function push_sendSubscriptionToServer(
  subscription: PushSubscription,
  method: string,
) {
  const key = subscription.getKey('p256dh')
  const authKey = subscription.getKey('auth')
  const encoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

  const endpoint = subscription.endpoint
  const publicKey = key
    ? // @ts-ignore
      btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
    : null
  const token = authKey
    ? // @ts-ignore
      btoa(String.fromCharCode.apply(null, new Uint8Array(authKey)))
    : null

  const body = {
    endpoint,
    publicKey,
    token,
    encoding,
  }

  return body

  // return fetch('push_subscription.php', {
  //   method,
  //   body: JSON.stringify(body),
  // }).then(() => subscription)
}

export function push_subscribe() {
  // changePushButtonState('computing')

  return (
    checkNotificationPermission()
      .then(() => navigator.serviceWorker.ready)
      .then((serviceWorkerRegistration) =>
        serviceWorkerRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
        }),
      )
      .then((subscription) => {
        // Subscription was successful
        // create subscription on your server
        return push_sendSubscriptionToServer(subscription, 'POST')
      })
      // .then((subscription) => subscription && changePushButtonState('enabled')) // update your UI
      .catch((e) => {
        if (Notification.permission === 'denied') {
          // The user denied the notification permission which
          // means we failed to subscribe and the user will need
          // to manually change the notification permission to
          // subscribe to push messages
          console.warn('Notifications are denied by the user.')
          // changePushButtonState('incompatible')
        } else {
          // A problem occurred with the subscription; common reasons
          // include network errors or the user skipped the permission
          console.error('Impossible to subscribe to push notifications', e)
          // changePushButtonState('disabled')
        }
      })
  )
}

function push_unsubscribe() {
  // changePushButtonState('computing')

  // To unsubscribe from push messaging, you need to get the subscription object
  navigator.serviceWorker.ready
    .then((serviceWorkerRegistration) =>
      serviceWorkerRegistration.pushManager.getSubscription(),
    )
    .then((subscription) => {
      // Check that we have a subscription to unsubscribe
      if (!subscription) {
        // No subscription object, so set the state
        // to allow the user to subscribe to push
        // changePushButtonState('disabled')
        return
      }

      // We have a subscription, unsubscribe
      // Remove push subscription from server
      return push_sendSubscriptionToServer(subscription, 'DELETE')
    })
    // .then((subscription) => subscription?.unsubscribe())
    // .then(() => changePushButtonState('disabled'))
    .catch((e) => {
      // We failed to unsubscribe, this can lead to
      // an unusual state, so  it may be best to remove
      // the users data from your data store and
      // inform the user that you have done so
      console.error('Error when unsubscribing the user', e)
      // changePushButtonState('disabled')
    })
}

function push_updateSubscription() {
  navigator.serviceWorker.ready
    .then((serviceWorkerRegistration) =>
      serviceWorkerRegistration.pushManager.getSubscription(),
    )
    .then((subscription) => {
      // changePushButtonState('disabled')

      if (!subscription) {
        // We aren't subscribed to push, so set UI to allow the user to enable push
        return
      }

      // Keep your server in sync with the latest endpoint
      return push_sendSubscriptionToServer(subscription, 'PUT')
    })
    // .then((subscription) => subscription && changePushButtonState('enabled')) // Set your UI to show they have subscribed for push messages
    .catch((e) => {
      console.error('Error when updating the subscription', e)
    })
}

const subscribe = () => {
  navigator.serviceWorker.register('/service-worker.js')

  // Use serviceWorker.ready to ensure that you can subscribe for push
  navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
    const options = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
    }
    serviceWorkerRegistration.pushManager.subscribe(options).then(
      (pushSubscription) => {
        console.log({ pushSubscription })
        // The push subscription details needed by the application
        // server are now available, and can be sent to it using,
        // for example, the fetch() API.
      },
      (error) => {
        // During development it often helps to log errors to the
        // console. In a production environment it might make sense to
        // also report information about errors back to the
        // application server.
        console.error(error)
      },
    )
  })
}
