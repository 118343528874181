import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import HomeContainer from './HomeContainer'
import HomeInput from './HomeInput'

import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'

const Home = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_STARTPAGE')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_STARTPAGE')}
        />
      </Helmet>

      <HomeContainer
        title={t('PYREXX SERVICE PORTAL')}
        description={t(
          'OUR CUSTOMER PORTAL IS AIMED AT ALL INHABITANTS WHOSE PROPERTY MANAGEMENT IS A PYREXX SERVICE CUSTOMER.',
        )}
        form={<HomeInput />}
      />
    </>
  )
}

export default Home
