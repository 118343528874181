import React from 'react'
import { useTranslation } from 'react-i18next'

import { Spinner } from 'pyrexx-react-library'

export function LoadingWrapper({
  children,
  loading,
  error,
  loadingComponent,
  errorComponent,
  hideLoading,
}) {
  const { t } = useTranslation()

  if (error) {
    return (
      errorComponent || (
        <span>
          {t(
            'SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER OR CONTACT THE SUPPORT',
          )}
        </span>
      )
    )
  }

  if (loading && !hideLoading) {
    return loadingComponent || <Spinner style={{ scale: '0.75' }} />
  }

  return children
}
