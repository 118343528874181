import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import HelpContainer from './HelpContainer'
import HelpInput from './HelpInput'

import { getValidUrlForLang } from '../../helper/getValidUrlForLang'
import { useAppContext } from '../../contexts/AppContext'

const Help = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useAppContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLanguage }}>
        <title>{t('PSP_SITE_TITLE_HELP')}</title>
        <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
        <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
        <meta name='robots' content='index,follow' />
        <meta
          name='description'
          content={t('PSP_SITE_META_DESCRIPTION_HELP')}
        />
      </Helmet>

      <HelpContainer
        title={t('HELP')}
        // description={t('PLACEHOLDER TEXT')}
        form={<HelpInput />}
      />
    </>
  )
}

export default Help
