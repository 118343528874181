import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'

import BackButton from '../../components/BackButton/BackButton'

import { AvailableRoutes } from '../../configs/RouteConfig'

import {
  ContainerStyled,
  DescriptionStyled,
  TitleStyled,
} from './Appointment.styles'

const AppointmentContainer = (props) => {
  const { title, description, form } = props

  return (
    <ContainerStyled component='article' xs sm md>
      <Row justify='between' align='center' wrap='nowrap' nogutter>
        <Col component='nav' xs={1}>
          <BackButton prevStep={'/'} />
        </Col>

        <Col>
          <TitleStyled>{title}</TitleStyled>
        </Col>

        <Col xs={1} />
      </Row>

      <Row justify='center' align='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          <DescriptionStyled>{description}</DescriptionStyled>
        </Col>
      </Row>

      <Row justify='center' style={{ marginBottom: '1rem' }}>
        <Col xs={12} sm={10} md={8} lg={6}>
          {form}
        </Col>
      </Row>
    </ContainerStyled>
  )
}

AppointmentContainer.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  form: PropTypes.element.isRequired,
}

export default AppointmentContainer
