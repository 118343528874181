import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'

import PSPCookieBanner from './PSPCookieBanner/PSPCookieBanner'
import AppRouter from '../routes/AppRouter'
import { ErrorFallback } from './ErrorFallback'

import useZammadChat from '../hooks/useZammadChat'

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const { i18n } = useTranslation()

  useZammadChat()

  const path = location.pathname.split('/').slice(2).join('/') + location.search

  // fix this for everything that comes after /de/
  useEffect(() => {
    i18n.on('languageChanged', function (lng) {
      const formattedlng = lng.substring(0, 2).toLowerCase()
      const newPath = '/' + formattedlng + '/' + path
      navigate(newPath, { replace: true })
    })
  }, [path])

  return (
    <>
      <PSPCookieBanner />

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.reload()}
        resetKeys={[location]}
      >
        <AppRouter />
      </ErrorBoundary>
    </>
  )
}

export default App
